// import { useUserAuth } from "../../../context/AuthProvider";
import Login from "./login";
import { msalInstance } from './msalConfig'; // Import the config from the separate file
import { MsalProvider } from '@azure/msal-react';
import React from "react";
// Import MSAL instance

const Auth = () => {
  // const { setInitialData }: any = useUserAuth();

  return (
    <div>
      {/* <MsalProvider instance={msalInstance}> */}

      <Login />

      {/* </MsalProvider> */}
    </div>
  );
};

export default Auth;
