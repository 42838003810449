import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import routes from "./routes/routes";
import Layout from "./components/layout/Layout";
import Missing from "./pages/missing";
import Home from "./pages/index";
import Auth from "./pages/login/index";
import Unauthorized from "./pages/unauthorized";
import RequireAuth from "./components/Auth/RequireAuth";
import AppLoading from "./components/Error/AppLoading";

import '@govtechsg/sgds/css/sgds.css';
import 'bootstrap/dist/css/bootstrap.min.css';





import Assessments from "./pages/Assessments";
import CreateAssessments from "./pages/Assessments/create-assessment";
import ManageTrainingEvents from "./pages/Trainings-Events";
import Roles from "./pages/roles";
import CreateRoles from "./pages/roles/create-roles";
import UserManagement from "./pages/user-management";
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from '../src/pages/login/msalConfig';


// Function to check if user is authenticated
const isAuthenticated = () => {
  const token = localStorage.getItem("decodedToken");
  return token !== null; // Check if the token exists
};


const App = () => {
  return (
    <Suspense fallback={<AppLoading />}>
      <Routes>
        <Route path="/" element={<Layout />}>


          <Route path="/create-assessments" element={<CreateAssessments />} />
          <Route path="/manage-trainings-events" element={<ManageTrainingEvents />} />
          <Route path="/roles" element={<Roles />} />
          <Route path="/create-roles" element={<CreateRoles />} />
          <Route path="/user-management" element={<UserManagement />} />

          {/* Auth route */}
          <Route
            path="/auth"
            element={
              <MsalProvider instance={msalInstance}>
                <Auth />
              </MsalProvider>
            }
          />
          {/* Protected Assessments Route */}
          <Route
            path="/assessments"
            element={
              // isAuthenticated() ? (
              <MsalProvider instance={msalInstance}>
                <Assessments />
              </MsalProvider>
              // ) : (
              //   <Navigate to="/auth" replace />
              // )
            }
          />

          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/404" element={<Missing />} />

          {/* {routes.map((item, index) => {
            return (
              <Route key={index} element={<RequireAuth allowedRoles={item.roles} />}>
                <Route path={item?.path} element={<item.component />} />
              </Route>
            );
          })} */}

          <Route path="*" element={<Navigate to={"/404"} />} />
        </Route>


      </Routes>




    </Suspense>
  );
};

export default App;
