import { PublicClientApplication, Configuration } from '@azure/msal-browser';

const msalConfig: Configuration = {
    auth: {
        clientId: '294a306d-f8dd-4967-95b6-a684eeadb4f2',
        authority: 'https://login.microsoftonline.com/949cc37d-701c-4cb5-900a-d72b06cd9763',
        // redirectUri: 'http://localhost:3000/auth/',
        redirectUri: ' https://wms.evvosports.com/assessments/',
    },
    cache: {
        cacheLocation: 'localStorage', // You can also use sessionStorage
        storeAuthStateInCookie: false,
    },
    // cache: {
    //     cacheLocation: 'sessionStorage', // Use session storage to store tokens during the session
    //     storeAuthStateInCookie: false,   // Ensure that cookies aren't used unless necessary
    // }
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
    scopes: ["openid", "profile", "User.Read", "Group.Read.All"], // Scopes for the ID token
};