import { Card, Col, Row } from "@govtechsg/sgds-react";
import React, { useEffect, useState } from 'react';
import SideNavBar from "../../components/Sidebar-left/sideBar";
import TopNav from "../../components/top-nav";
import Footer from "../../components/footer";
import { Container, Dropdown, Pagination, Modal, Button, Form } from "react-bootstrap";
import ReactFlatpickr from 'react-flatpickr';
import Select from 'react-select';
import 'flatpickr/dist/flatpickr.css';
import CalendarComponent from "../../components/calendar";
import MyDatePicker from "../../components/datepicker";
import { createTrainingApi, fetchDepartment, fetchTrainingsListByDateRange } from '../../service/common.service';
import moment from 'moment';

const options = [
  { value: 'Outdoor Training', label: 'Outdoor Training' },
  { value: 'Indoor Training', label: 'Indoor Training' }
];

const optionsArea = [
  { value: 'Ground 1', label: 'Ground 1' },
  { value: 'Ground 2', label: 'Ground 2' }
];

interface FormData {
  type: string;
  trainingProgram?: { value: string; label: string };
  trainingTitle?: string;
  trainingDescription?: string;
  department?: { value: string; label: string };
  area?: { value: string; label: string };
  startDate?: Date | null;
  endDate?: Date | null;
  startTime?: string;
  endTime?: string;
}

interface FormErrors {
  type?: string;
  trainingProgram?: string;
  trainingTitle?: string;
  trainingDescription?: string;
  department?: string;
  area?: string;
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
}


const ManageTrainingEvents = () => {
  const [formData, setFormData] = useState<FormData>({
    type: "training",
    trainingProgram: undefined,
    trainingTitle: '',
    trainingDescription: '',
    department: undefined,
    area: undefined,
    startDate: null,
    endDate: null,
    startTime: '',
    endTime: ''
  });

  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const [date, setDate] = useState(new Date());
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [error, setError] = useState('')
  const [trainingList, setTrainingList] = useState([]);


  const handleOpenFirstModal = () => setShowFirstModal(true);

  const handleCloseFirstModal = () => {
    setShowFirstModal(false);

    setFormData({
      type: "training",
      trainingProgram: undefined,
      trainingTitle: '',
      trainingDescription: '',
      department: undefined,
      area: undefined,
      startDate: null,
      endDate: null,
      startTime: '',
      endTime: ''
    });
  };

  const handleOpenSecondModal = () => {
    setShowFirstModal(false);
    setShowSecondModal(true);
  };
  const handleCloseSecondModal = () => setShowSecondModal(false);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (name: any, selectedOption: any) => {
    setFormData({ ...formData, [name]: selectedOption });
  };

  const optionsDepartment = departments.map((el: any) => ({
    value: el.id,
    label: el.departmentName,
  }));


  const validateForm = (
    formData: {
      type: string;
      trainingProgram: string;
      trainingTitle: string;
      trainingDescription: string;
      department: any;
      area: string;
      startDate: string | null;
      endDate: string | null;
      startTime: string;
      endTime: string;
    },
    setFormErrors: any
  ) => {
    let errors: Record<string, string> = {};

    // Check for empty fields and add error messages
    if (!formData.type) {
      errors.type = 'Training Type is required';
    }
    if (!formData.trainingProgram || formData.trainingProgram.trim() === '') {
      errors.trainingProgram = 'Training Program is required';
    }
    if (!formData.trainingTitle || formData.trainingTitle.trim() === '') {
      errors.trainingTitle = 'Training Title is required';
    } else if (formData.trainingTitle.length <= 3) {
      errors.trainingTitle = 'Training Title must be more than 3 characters';
    }
    if (!formData.trainingDescription || formData.trainingDescription.trim() === '') {
      errors.trainingDescription = 'Training Description is required';
    } else if (formData.trainingDescription.length <= 3) {
      errors.trainingDescription = 'Training Description must be more than 3 characters';
    }

    if (!formData.department) {
      errors.department = 'Department is required';
    }


    if (!formData.area || formData.area.trim() === '') {
      errors.area = 'Area & Location is required';
    }


    if (!formData.startDate) {
      errors.startDate = 'Start Date is required';
    } else {
      const today = new Date();
      // today.setHours(0, 0, 0, 0); // Set to midnight
      const startDate = new Date(formData.startDate);

      // Ensure date is today or in the future
      if (startDate >= today) {
        errors.startDate = 'Start Date cannot be in the past';
      }
    }

    // Validate End Date
    if (!formData.endDate) {
      errors.endDate = 'End Date is required';
    } else {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset time to the start of the day
      const endDate = new Date(formData.endDate);

      if (endDate < today) {
        errors.endDate = 'End Date cannot be in the past';
      } else if (formData.startDate && new Date(formData.startDate) > endDate) {
        errors.endDate = 'End Date must be after the Start Date';
      }
    }

    if (!formData.startTime || formData.startTime.trim() === '') {
      errors.startTime = 'Start Time is required';
    }
    if (!formData.endTime || formData.endTime.trim() === '') {
      errors.endTime = 'End Time is required';
    }

    // Validate Start Time
    if (formData.startTime) {
      const startDate = new Date(formData.startDate!);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (startDate.toDateString() === today.toDateString()) {
        const currentTime = new Date();
        const [hours, minutes] = formData.startTime.split(':').map(Number);
        const startTime = new Date(today);
        startTime.setHours(hours, minutes, 0, 0);

        if (startTime <= currentTime) {
          errors.startTime = 'Start time cannot be in the past';
        }
      }
    }



    // Validate End Time
    if (formData.endTime) {
      const startDate = new Date(formData.startDate!);
      const endTime = new Date(`${startDate.toISOString().split('T')[0]}T${formData.endTime}:00`);

      if (formData.startTime) {
        const startTime = new Date(`${startDate.toISOString().split('T')[0]}T${formData.startTime}:00`);
        if (endTime <= startTime) {
          errors.endTime = 'End time must be after the Start time';
        }
      }
    }


    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    setFormErrors({})
    const processedFormData = {
      ...formData,
      trainingProgram: formData.trainingProgram?.value ?? '',
      trainingTitle: formData.trainingTitle ?? '',
      trainingDescription: formData.trainingDescription ?? '',
      department: formData.department?.value ?? '',
      // department: typeof formData.department === 'string' ? formData.department : formData.department?.name ?? '',
      area: formData.area?.value ?? '',
      // startDate: formData.startDate ? formData.startDate.toISOString().slice(0, 10) : null, // Convert Date to string
      startDate: formData.startDate ? new Date(formData.startDate).toString() : null, // Convert Date to string
      // endDate: formData.endDate ? formData.endDate.toISOString().slice(0, 10) : null, // Convert Date to string
      endDate: formData.endDate ? new Date(formData.endDate).toString() : null, // Convert Date to string
      startTime: formData.startTime ?? '',
      endTime: formData.endTime ?? '',
      type: formData.type ?? ''
    };

    // Validate the form before submitting
    if (!validateForm(processedFormData, setFormErrors)) return;


    const payload = {
      trainingType: formData.type || '',
      trainingProgram: processedFormData.trainingProgram,
      trainingTitle: processedFormData.trainingTitle,
      trainingDescription: processedFormData.trainingDescription,
      departmentId: processedFormData.department,
      area_location: processedFormData.area,
      // startDate: processedFormData.startDate, // Already in correct string format
      // endDate: processedFormData.endDate,     // Already in correct string format
      startDate: moment(processedFormData.startDate).format('YYYY-MM-DD'),
      endDate: moment(processedFormData.endDate).format('YYYY-MM-DD'),
      startTime: processedFormData.startTime,
      endTime: processedFormData.endTime
    };

    try {
      // console.log(payload, '-<<payload');

      const response = await createTrainingApi(payload);

      if (response?.status == 200) {
        setFormErrors({})

      } else if (response?.status == 400) {
        setFormErrors(response?.data?.message || 'error');
      } else {
        setFormErrors(response?.data?.message || 'error');
      }

    } catch (error) {
      // Handle error
      console.error("Error creating training:", error);
    }
  };

  const fetchDepartmentsList = async () => {
    try {
      const response: any = await fetchDepartment();

      if (response?.status == 200) {
        setDepartments(response?.data?.data)

      } else if (response?.status == 400) {
        setError(response?.data?.message || 'error');
      } else {
        setError(response?.data?.message || 'error');
      }

    } catch (error) {
      console.error("Error fetching coach list:", error);
    }
  };

  const fetchTrainings = async () => {
    try {
      let dayRequest = {
        type: "training",
        userId: 37,
        dateType: "day", // day, week, month
        // "fromDate": "2024-09-02T00:00:00",
        // "toDate": "2024-10-09T23:59:59"
        date: "2024-10-08"
      }
      const response: any = await fetchTrainingsListByDateRange(dayRequest);

      console.log(response?.data?.data, '-<,kjfak');

      if (response?.status == 200) {
        setTrainingList(response?.data?.data)

      } else if (response?.status == 400) {
        setError(response?.data?.message || 'error');
      } else {
        setError(response?.data?.message || 'error');
      }

    } catch (error) {
      console.error("Error fetching coach list:", error);
    }
  };


  useEffect(() => {
    fetchDepartmentsList();
    fetchTrainings();
  }, [])


  // Map the training data to FullCalendar event format
  const events = trainingList.map((training: any, index) => {
    let backgroundColor = '';
    if (index % 3 === 0) {
      backgroundColor = 'blue';
    } else if (index % 3 === 1) {
      backgroundColor = 'red';
    } else {
      backgroundColor = 'green';
    }

    return {
      title: training.trainingTitle,
      start: training.startTime, // assuming training has a startTime field
      end: training.endTime, // assuming training has an endTime field
      backgroundColor: backgroundColor,
      borderColor: backgroundColor,
    };
  });


  return (
    <>
      <TopNav />
      <div className="d-flex align-items-stretch">
        <SideNavBar />
        <div className="page-content pb-4">
          <div className="bredcrumb">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Trainings & Events</li>
            </ul>
          </div>
          <section className="blockWrap no-padding-bottom p-0">
            <div className="card">
              <Row className="g-0">
                <Col className="col app-calendar-sidebar">
                  <div className="border-bottom ps-4 pe-4 my-sm-0 mb-3">
                    <Button variant="primary" onClick={handleOpenFirstModal} className="d-flex w-100 align-items-center justify-content-between">
                      Create Training or Event <span className="sign">+</span>
                    </Button>
                  </div>

                  <div className="calendarWrap p-4 border-bottom">
                    <ReactFlatpickr
                      value={date}
                      onChange={([selectedDate]) => setDate(selectedDate)}
                      options={{ inline: true, dateFormat: 'Y-m-d' }}
                    />
                  </div>
                </Col>
                <Col className="col app-calendar-content">
                  <div className="fullCalendarWrap">
                    <CalendarComponent />
                    {error && <p className="error-message">{error}</p>}
                  </div>
                </Col>
              </Row>
            </div>
          </section >
          <Footer />
        </div >
      </div >

      {/* First Modal */}
      <Modal Modal className="mw-630" show={showFirstModal} onHide={handleCloseFirstModal} >
        <Modal.Header closeButton>
          <Modal.Title>Create Training or Event</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="inline-fields-wrap mt-0 mb-4">
            <div className="custom-check-field custom-radio fw-700">
              {/* <input type="radio" name="rate" value="Training" /> */}
              <input
                type="radio"
                name="rate"
                value="training"
                checked={formData.type === "training"}
                onChange={(e) => setFormData({ ...formData, type: e.target.value })}
              />
              <label>Training</label>
            </div>

            <div className="custom-check-field custom-radio fw-700">
              {/* <input type="radio" name="rate" value="Good" /> */}
              <input
                type="radio"
                name="rate"
                value="event"
                checked={formData.type === "event"}
                onChange={(e) => setFormData({ ...formData, type: e.target.value })}
              />
              <label>Event</label>
            </div>


          </div>
          <div className="form-group">
            <label>Training Program</label>
            <div className="custom-select-wrap">
              <Select
                className="selectpicker"
                placeholder="Select role type"
                options={options}
                onChange={(selectedOption) => handleSelectChange('trainingProgram', selectedOption)}
              />
              {formErrors.trainingProgram && <div className="text-danger">{formErrors.trainingProgram}</div>}
            </div>
          </div>
          <div className="form-group">
            <label>Training Title</label>
            <input
              type="text"
              name="trainingTitle"
              value={formData.trainingTitle}
              onChange={handleInputChange}
              placeholder="Enter Training Title"
            />
            {formErrors.trainingTitle && <div className="text-danger">{formErrors.trainingTitle}</div>}
          </div>
          <div className="form-group">
            <label>Training Description</label>
            <textarea
              name="trainingDescription"
              value={formData.trainingDescription}
              onChange={handleInputChange}
              placeholder="Enter Training Description"
            />
            {formErrors.trainingDescription && <div className="text-danger">{formErrors.trainingDescription}</div>}
          </div>
          <Row>
            <Col lg="6">
              <div className="form-group">
                <label>Start Date</label>
                <MyDatePicker
                  value={formData.startDate || null}
                  onChange={(date: Date | null) => {
                    console.log(date, '-<,date');
                    if (date instanceof Date && !isNaN(date.getTime())) {
                      const normalizedDate = new Date(date);
                      normalizedDate.setHours(0, 0, 0, 0);
                      setFormData({ ...formData, startDate: normalizedDate });
                    } else {
                      console.error('Invalid date selected');
                    }
                  }}
                />


                {formErrors.startDate && <div className="text-danger">{formErrors.startDate}</div>}
              </div>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <label>End Date</label>
                <MyDatePicker
                  value={formData.endDate || null}
                  onChange={(date: any) => setFormData({ ...formData, endDate: date })}
                />
                {formErrors.endDate && <div className="text-danger">{formErrors.endDate}</div>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="form-group">
                <label>Start Time</label>
                <div className="iconFields">
                  <input
                    type="time"
                    name="startTime"
                    value={formData.startTime}
                    onChange={handleInputChange}
                  />
                  <i className='icon icon-clock fw-400'></i>
                </div>
                {formErrors.startTime && <div className="text-danger">{formErrors.startTime}</div>}
              </div>
            </Col>
            <Col lg="6">
              <div className="form-group">
                <label>End Time</label>
                <div className='iconFields'>
                  <input
                    type="time"
                    name="endTime"
                    value={formData.endTime}
                    onChange={handleInputChange}
                  />
                  <i className='icon icon-clock fw-400'></i>
                </div>
                {formErrors.endTime && <div className="text-danger">{formErrors.endTime}</div>}
              </div>
            </Col>
          </Row>
          <div className="form-group">
            <label>Department</label>
            <div className="custom-select-wrap">
              <Select
                className="selectpicker"
                placeholder="Select department"
                // options={optionsDepartment}
                options={optionsDepartment}
                onChange={(selectedOption) => handleSelectChange('department', selectedOption)}
              />
              {formErrors.department && <div className="text-danger">{formErrors.department}</div>}
            </div>
          </div>
          <div className="form-group">
            <label>Area & Location</label>
            <div className="custom-select-wrap">
              <Select
                className="selectpicker"
                placeholder="Select Area & Location"
                options={optionsArea}
                onChange={(selectedOption) => handleSelectChange('area', selectedOption)}
              />
              {formErrors.area && <div className="text-danger">{formErrors.area}</div>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Create New Training
          </Button>
          <Button variant="secondary" onClick={handleCloseFirstModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* </ > */}

      {/* Success Modal */}
      < Modal show={showSecondModal} onHide={handleCloseSecondModal} >
        <Modal.Header closeButton>
          <Modal.Title>Training Created Successfully!</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseSecondModal}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      {/* </ > */}
    </>
  );
};

export default ManageTrainingEvents;