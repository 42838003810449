import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'; // For month view
import timeGridPlugin from '@fullcalendar/timegrid'; // For week and day views
import listPlugin from '@fullcalendar/list'; // For list view
import { EventClickArg, DatesSetArg } from '@fullcalendar/core';
import { format, startOfMonth, endOfMonth, startOfWeek, endOfWeek } from 'date-fns';
import { fetchTrainingsListByDateRange } from '../../service/common.service';

interface Training {
  id: string;
  trainingTitle: string;
  startDate: string; // ISO format
  endDate: string;   // ISO format
  startTime: string; // Format: "HH:mm:ss"
  endTime: string;   // Format: "HH:mm:ss"
  attendanceStatus: string;
  trainingType: string;
  trainingProgram: string;
  status: string | null;
}

const CalendarComponent: React.FC = () => {
  const [trainingList, setTrainingList] = useState<Training[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [currentView, setCurrentView] = useState('dayGridMonth'); // Track the current view
  const [currentDate, setCurrentDate] = useState(new Date()); // Track the current date

  const fetchTrainings = async (dateRange: { fromDate: string; toDate: string; dateType: string }) => {
    try {
      const dayRequest = {
        type: "training",
        userId: 37,
        dateType: dateRange.dateType, // Use dateType from the passed dateRange
        fromDate: dateRange.fromDate, // Use the start date from the passed dateRange
        toDate: dateRange.toDate // Use the end date from the passed dateRange
      };

      // console.log(dayRequest, '-<<dayRequest');

      const response: any = await fetchTrainingsListByDateRange(dayRequest);

      if (response?.status === 200) {
        setTrainingList(response?.data?.data);
      } else {
        setError(response?.data?.message || 'Error fetching training list');
      }

    } catch (error) {
      console.error("Error fetching training list:", error);
      setError('Error fetching training list');
    }
  };

  const handleEventClick = (arg: EventClickArg) => {
    alert('Event clicked: ' + arg.event.title);
  };

  const generateEventsForSingleDay = (events: Training[]): any[] => {
    return events.map(event => {
      const attendanceColor = getColorForAttendanceStatus(event.attendanceStatus);
      return {
        title: event.trainingTitle,
        start: `${event.startDate.split('T')[0]}T${event.startTime}Z`,
        end: `${event.startDate.split('T')[0]}T${event.endTime}Z`,
        backgroundColor: attendanceColor,
        borderColor: attendanceColor,
      };
    });
  };


  const getColorForAttendanceStatus = (attendanceStatus: string): string => {
    switch (attendanceStatus) {
      case "Attended":
        return "#28a745"; // Green for present
      case "Missed":
        return "#dc3545"; // Red for absent
      case "InProgress":
        return "#ff9800"; // Orange for ongoing
      case "Pending":
        return "#007bff"; // Blue for pending
      default:
        return "#6c757d"; // Gray for unspecified attendance statuses
    }
  };


  const handleDatesSet = (arg: DatesSetArg) => {
    const viewType = arg.view.type;
    const startDate = new Date(arg.startStr);
    const endDate = new Date(arg.endStr);

    let dateRange = {
      fromDate: '',
      toDate: '',
      dateType: ''
    };

    // For month view
    if (viewType === 'dayGridMonth') {
      dateRange = {
        fromDate: format(startDate, 'yyyy-MM-dd'),
        toDate: format(endDate, 'yyyy-MM-dd'),
        dateType: 'month'
      };
    }
    // For week view
    else if (viewType === 'timeGridWeek') {
      dateRange = {
        fromDate: format(startDate, 'yyyy-MM-dd'),
        toDate: format(endDate, 'yyyy-MM-dd'),
        dateType: 'week'
      };
    }
    // For day view
    else if (viewType === 'timeGridDay') {
      dateRange = {
        fromDate: format(startDate, 'yyyy-MM-dd'),
        toDate: format(endDate, 'yyyy-MM-dd'),
        dateType: 'day'
      };
    }

    fetchTrainings(dateRange);  // Fetch trainings based on the new date range
    setCurrentView(viewType);   // Update the current view
    setCurrentDate(startDate);  // Update the current date
  };



  const generatedEvents = generateEventsForSingleDay(trainingList);

  useEffect(() => {
    // Ensure we use the correct month when fetching initially
    const today = new Date();
    const monthStart = startOfMonth(today);
    const monthEnd = endOfMonth(today);

    fetchTrainings({
      fromDate: format(monthStart, 'yyyy-MM-dd'),
      toDate: format(monthEnd, 'yyyy-MM-dd'),
      dateType: 'month'
    });
  }, []); // Only run on initial load

  return (
    <div className="calendar-container">
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
        initialView={currentView} // Start with the stored current view
        headerToolbar={{
          left: 'prev,next,title',
          center: '',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        events={generatedEvents}
        eventClick={handleEventClick}
        eventDisplay="block"
        dayMaxEvents={3}  // Show only 3 events and then "+more" link
        moreLinkClick="popover"  // Show a popover when clicking on "+more"
        datesSet={handleDatesSet} // Set the dates set handler
      />
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default CalendarComponent;