import { Card, Col, Row } from "@govtechsg/sgds-react";
import { useState, useEffect } from 'react';
import SideNavBar from "../../components/Sidebar-left/sideBar";
import TopNav from "../../components/top-nav";
import Footer from "../../components/footer";
import { Container, Dropdown, Pagination, Modal, Button } from "react-bootstrap";
import { jwtDecode } from 'jwt-decode'; // Fix: jwtDecode should be imported without curly braces
import { useMsal } from "@azure/msal-react";

const Assessments = () => {
  const { instance, accounts } = useMsal();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  console.log(instance, accounts);

  useEffect( ()=>{


    
  const getResponse=async()=>{
      try {
        // const loginResponse = await instance.handleRedirectPromise();
        
        // if (loginResponse) {
        //     console.log('Login successful:', loginResponse);
        //     // Store the access token if needed
        //     const accessToken = loginResponse.accessToken;
        //     // Navigate or update state with the access token
        // }

        const tokenResponse = await instance.acquireTokenByCode({
          scopes: ["openid", "profile", "User.Read"],
          code: '0.AVMAfcOclBxwtUyQCtcrBs2XY20wSind-GdJlbamhO6ttPJTAAA.AgABBAIAAADW6jl31mB3T7ugrWTT8pFeAwDs_wUA9P9khou7FDIm-GA2WD8AFYxp348hPIf1PBEhh5letM1EQt5bExJpQypZD9pjqk0tD1Ajh9zGYkeOZTNtOuXCVkcMCWXjL3azfqer-uEmgUUiYpBHl8vQ3CBgGykyzb0Tcbxmy5nWeUOvXhGwwCmKxxEZ_DU5XKz9HAtYpI2lhExWcYKhTQA7Xsvzg80Kb0UnSTUPNBmZW4XK_ZKIc4XkPwRBZ-zas3Y_u-eMcocPeGybvic47z07_CPYn34OddTFHua_lToMIoZIc4p_mb6vSdJY3_2XdOReQRORrT6eQJsol_qNplhidBm_dLlrIXgwmqEVXjyzoLZx8QbC4a2AXb84m2do5ULeGH3ixpGVmnIprAmER2AMFlKazlD8d1zeBRlgW4Jwnt-993JypwHwG9Jv4qFcsUTz7zgxtaOCN9WudkiV4ALrbKd3_uUT1xhJG9lgjWYK3m3EezJztocFgaX8VXxILrUj0EdV4S19Ind09Z9tt698V2BCmccWI1hIr_ofkrF4TCO6E-KlWmqVfW2ZgXZC9sxmWzNCPafc2OVK_zY9qD0MXtkwbLUbunis_F3lV1vCthS_BdSN6f88Eyzp1OMycu9o7Sq6fiClj0bK4gz9Um1zRXpkD0msES65aHBp-v2nK1HRAkpCib5gVk3zb_aWqWM1fMi1uIP7U-Ugcyg21zuDQvGOVgZp9or8MxZunqzVm4ZVFThihahZ1nHktKWWBT5cABayJsoWqpd5fQHBPBn9Dm0mbsPEn46_gWjv3Y5gM4ywMsgIPZ1NrhOjN_VM3tKALaGqTHg0RdT9R0LuhE8RKu158UqKIjLJTwOn6ry516WEe2SR8-lRc4Itrqa8MBAW2cMYsej3ACED1-S0D2-JCNbnGiWLK_DUvx8r&client_info=eyJ1aWQiOiIxNGI3N2NhOC1mNzUyLTQ4NjItYWRjYy1lZDg4MTQ3ZmY1NjQiLCJ1dGlkIjoiOTQ5Y2MzN2QtNzAxYy00Y2I1LTkwMGEtZDcyYjA2Y2Q5NzYzIn0&state=eyJpZCI6IjAxOTI3NGNhLWVlZWQtN2M1MS05OWIyLTA1YTg1MTQ3OTU5YiIsIm1ldGEiOnsiaW50ZXJhY3Rpb25UeXBlIjoicmVkaXJlY3QifX0%3d&session_state=48cc4bf6-8b74-4174-98ab-ddd13992a0d6',
          redirectUri: "http://localhost:3000/assessments/", // Should match your app's redirect URI
        });
        console.log('tokenResponse>>',tokenResponse)
        const accessToken = tokenResponse.accessToken;
        console.log("Access Token:", accessToken);

    } catch (error) {
        console.error('Authentication error:', error);
    }

  //   const silentRequest = {
  //     scopes: ['openid', 'profile', 'User.Read'], // Adjust scopes as needed
  // };

  // try {
  //   alert('1')
  //     const response = await instance.acquireTokenSilent(silentRequest);
  //     alert('')
  //     console.log('response>>',response)
  //     return response.accessToken; // Return the access token
  // } catch (error) {
  //     console.error("Error acquiring token silently:", error);
  //     throw error; // Handle the error appropriately
  // }

   }
   //getResponse()
  },[])
  // console.log(instance.acquireTokenSilent, '-<,acquireTokenSilent');

  // useEffect(() => {
  //   const fetchUserDetails = async () => {
  //     if (accounts.length > 0) {
  //       try {
  //         const response = await instance.acquireTokenSilent({
  //           scopes: ["openid", "profile", "User.Read", "Group.Read.All"],
  //           account: accounts[0],
  //         });

  //         const decodedToken: any = jwtDecode(response.idToken);

  //         console.log(decodedToken, '-<,decodedToken');

  //         // Store the decoded token in localStorage
  //         localStorage.setItem("decodedToken", JSON.stringify(decodedToken));
  //         // setUserInfo(decodedToken);

  //         // Fetch user's groups from Microsoft Graph API
  //         const groupResponse = await fetch(`https://graph.microsoft.com/v1.0/me/memberOf`, {
  //           headers: {
  //             Authorization: `Bearer ${response.accessToken}`,
  //           },
  //         });
  //         const groupData = await groupResponse.json();

  //         // Extract group IDs from the response
  //         const groupIds = groupData.value.map((group: any) => group.id);
  //         // setGroups(groupIds);

  //         // Store group IDs in localStorage
  //         // localStorage.setItem("groupIds", JSON.stringify(groupIds));

  //         // Insert into the database via API
  //         // await storeUserData(decodedToken, groupIds);

  //         // After successfully storing data, redirect to assessments
  //         // navigate("/assessments");

  //       } catch (error) {
  //         console.error("Error during token acquisition or fetching groups: ", error);
  //         // handleMicrosoftLogin(); // Fallback to login if token acquisition fails
  //       }
  //     }
  //   };

  //   fetchUserDetails();
  // }, [accounts, instance]);

  return (
    <>
      <TopNav />
      <div className="d-flex align-items-stretch">
        <SideNavBar />
        <div className="page-content pb-4">
          <div className="bredcrumb">
            <ul>
              <li><a href="#">Home</a></li>
              <li>Assessment</li>
            </ul>
          </div>
          <section className="blockWrap no-padding-bottom p-0">
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between">
                <div className="cardTitle">
                  <h5>Assessments</h5>
                </div>

                <button className="btn btn-outline-primary" onClick={handleShow}>Create Assessment <i className="icon-plus-sign"></i></button>
              </div>

              <div className="card-body">
                <div className="filterBar">
                  <div className="title">Total Assessments <span className="theme-color">[8]</span></div>
                  <div className="searchBar">
                    <input type="text" placeholder="Search by assessment" value="" />
                    <button className="searchBtn"><i className="icon-search-sign"></i></button>
                  </div>
                </div>

                <div className="assessmentsBlockWrap">
                  <div className="assessmentsBlock">
                    <div className="assessmentsBlockLeft">
                      <h5>Advanced Rescue Operations</h5>
                      <div className="dateBlock">
                        <span className="dateBlockIn">Created : <span className="value">12/05/2024</span></span>
                        <span className="dateBlockIn">Team : <span className="value">Civil Squads</span></span>
                      </div>
                    </div>

                    <div className="assessmentsBlockRight">
                      <div className="assessmentsQuestWrap">
                        <div className="assessmentsQuest">
                          <span className="label">Questions</span>
                          <div className="value">08</div>
                        </div>
                        <div className="assessmentsQuest">
                          <span className="label">Assigned</span>
                          <div className="value">120</div>
                        </div>
                        <div className="assessmentsQuest">
                          <span className="label">Response</span>
                          <div className="value">89</div>
                        </div>
                      </div>

                      <div className="actionWrap">
                        <button className="graphLink" onClick={handleShow}><span className="icon-graph-v1"><span className="graphIc"></span></span></button>
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic">
                            <i className="icon-dots-v"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                    </div>
                  </div>

                  <div className="assessmentsBlock">
                    <div className="assessmentsBlockLeft">
                      <h5>Advanced Rescue Operations</h5>
                      <div className="dateBlock">
                        <span className="dateBlockIn">Created : <span className="value">12/05/2024</span></span>
                        <span className="dateBlockIn">Team : <span className="value">Civil Squads</span></span>
                      </div>
                    </div>

                    <div className="assessmentsBlockRight">
                      <div className="assessmentsQuestWrap">
                        <div className="assessmentsQuest">
                          <span className="label">Questions</span>
                          <div className="value">08</div>
                        </div>
                        <div className="assessmentsQuest">
                          <span className="label">Assigned</span>
                          <div className="value">120</div>
                        </div>
                        <div className="assessmentsQuest">
                          <span className="label">Response</span>
                          <div className="value">89</div>
                        </div>
                      </div>

                      <div className="actionWrap">
                        <button className="graphLink" onClick={handleShow}><span className="icon-graph-v1"><span className="graphIc"></span></span></button>
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic">
                            <i className="icon-dots-v"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                    </div>
                  </div>

                  <div className="assessmentsBlock">
                    <div className="assessmentsBlockLeft">
                      <h5>Advanced Rescue Operations</h5>
                      <div className="dateBlock">
                        <span className="dateBlockIn">Created : <span className="value">12/05/2024</span></span>
                        <span className="dateBlockIn">Team : <span className="value">Civil Squads</span></span>
                      </div>
                    </div>

                    <div className="assessmentsBlockRight">
                      <div className="assessmentsQuestWrap">
                        <div className="assessmentsQuest">
                          <span className="label">Questions</span>
                          <div className="value">08</div>
                        </div>
                        <div className="assessmentsQuest">
                          <span className="label">Assigned</span>
                          <div className="value">120</div>
                        </div>
                        <div className="assessmentsQuest">
                          <span className="label">Response</span>
                          <div className="value">89</div>
                        </div>
                      </div>

                      <div className="actionWrap">
                        <button className="graphLink" onClick={handleShow}><span className="icon-graph-v1"><span className="graphIc"></span></span></button>
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic">
                            <i className="icon-dots-v"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                    </div>
                  </div>

                </div>


                <div className="mt-4 justify-content-center d-flex">
                  <Pagination>
                    <Pagination.Prev className="prev" />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item active>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Next className="next" />
                  </Pagination>
                </div>

              </div>
            </div>


          </section>

          <Footer />
        </div>
      </div>

      <Modal className="mw-530" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="form-group">
            <label>Assessment Title</label>
            <input type="text" value="" placeholder="Enter Assessment Title" />
          </div>
          <div className="form-group">
            <label>Assessment Category</label>
            <select>
              <option>Mental health monitoring</option>
              <option>Mental health monitoring</option>
              <option>Mental health monitoring</option>
            </select>
          </div>
          <div className="form-group">
            <label>Select Team</label>
            <select>
              <option>Team 1</option>
              <option>Team 2</option>
              <option>Team 3</option>
            </select>
          </div>
          <div className="form-group">
            <label>Frequency </label>
            <select>
              <option>Daily</option>
              <option>Weekly</option>
              <option>Monthly</option>
              <option>Yearly</option>
            </select>
          </div>
          <div className="form-group">
            <label>Assessment Description </label>
            <textarea placeholder="Lorem ipsum is placeholder text commonly used in the graphic,
print, and publishing industries for previewing layouts and 
visual mockups."></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer className="bt-0 pt-0 pb-4">
          <Button variant="primary" onClick={handleClose}>
            Create  New Assessment
          </Button>
          <Button variant="outline-primary" className="me-0" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Assessments;
