import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { SideNav, SideNavCollapse, SideNavLink } from "@govtechsg/sgds-react";

const SideNavBar = () => {
  return (
    <nav className="" id="sidebar">
      <div className="sidebar-header d-flex align-items-center">
        <div className="title">
          <h1 className="h5">Welcome to SCDF!</h1>
          <p className="adminName">Mr. John Doe</p>
        </div>
      </div>
      <SideNav className="list-unstyled">
        <SideNav.Item eventKey="0">
          <a href="#">Dashboard</a>
        </SideNav.Item>

        <SideNav.Item eventKey="1">
          <a href="#">Health Data</a>
        </SideNav.Item>

        <SideNav.Item eventKey="2">
          <a href="roles">Roles</a>
        </SideNav.Item>

        <SideNav.Item eventKey="3">
          <SideNav.Button href="#">
            Trainings & Events
          </SideNav.Button>
          <SideNavCollapse>
            <>
              <SideNavLink eventKey="4" href="manage-trainings-events">
                Manage Trainings / Events
              </SideNavLink>
              <SideNavLink eventKey="5" href="#">
                Training Program
              </SideNavLink>
              <SideNavLink eventKey="6" href="#">
                Exercise List
              </SideNavLink>
            </>
          </SideNavCollapse>
        </SideNav.Item>

        <SideNav.Item eventKey="7">
          <a href="assessments">Assessments</a>
        </SideNav.Item>

        <SideNav.Item eventKey="8">
          <a href="user-management">User Management</a>
        </SideNav.Item>

        <SideNav.Item eventKey="9">
          <a href="#">Help & Support</a>
        </SideNav.Item>     

        <SideNav.Item eventKey="10">
          <a href="auth">
            <i className="icon-logout md-20"></i> Logout
          </a>
        </SideNav.Item>
      </SideNav>
    </nav>
  );
};

export default SideNavBar;
