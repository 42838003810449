import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface MyDatePickerProps {
  value: Date | null;
  onChange: (date: Date | null) => void;
}

const MyDatePicker: React.FC<MyDatePickerProps> = ({ value, onChange }) => {
  return (
    <div className='iconFields'>
      <DatePicker
        selected={value}
        onChange={(date: Date | null) => onChange(date)}
        placeholderText="Select a date"
      />
      <i className='icon icon-calendar fw-700'></i>
    </div>
  );
};

export default MyDatePicker;


// import React, { useState } from 'react';
// import React, { useState } from 'react';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';

// const MyDatePicker = () => {
//   // Initialize with null if you want to allow unselected dates
//   const [startDate, setStartDate] = useState<Date | null>(null);

//   return (
//     <div className='iconFields'>
//       <DatePicker
//         selected={startDate}
//         onChange={(date: Date | null) => setStartDate(date)}
//         placeholderText="Select a date"
//       />
//       <i className='icon icon-calendar fw-700'></i>
//     </div>
//   );
// };

// export default MyDatePicker;


