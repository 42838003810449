// ---------------- fetching the group details : 

import React, { useEffect, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { InputGroup, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { groupSyncApi } from '../../service/common.service';

const GRAPH_API_BASE_URL = "https://graph.microsoft.com/v1.0";

const Login = () => {
    const { instance, accounts, inProgress } = useMsal();
    const navigate = useNavigate();
    const account = useAccount(accounts[0] || {});
    const [groups, setGroups] = useState<any[]>([]);
    const [groupMembers, setGroupMembers] = useState<any>({});
    const [loaderStatus, setLoaderStatus] = useState(0)
    const [error, setError] = useState('')

    // Function to handle Microsoft login
    const handleMicrosoftLogin = () => {
        instance
            .loginRedirect({
                scopes: ["openid", "profile", "User.Read", "Group.Read.All"],
            })
            .catch((e) => console.error("Login Error: ", e));
    };

    // Function to fetch group details
    const fetchGroups = async (accessToken: string) => {
        try {
            const groupResponse = await axios.get(
                `${GRAPH_API_BASE_URL}/me/memberOf`, // Fetch user's group memberships
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            const groupsData = groupResponse.data.value.map((group: any) => ({
                id: group.id,
                displayName: group.displayName,
                mail: group.mail,
            }));

            // setGroups(groupsData);
            // localStorage.setItem("groups", JSON.stringify(groupsData)); // Store groups in local storage

            groupSync({ "groups": groupsData })
            // Fetch members for each group
            // groupsData.forEach((group: any) => {
            //     fetchGroupMembers(accessToken, group.id); // Fetch group members
            // });
        } catch (error) {
            console.error("Error fetching groups: ", error);
        }
    };

    // // Function to fetch group members by groupId
    // const fetchGroupMembers = async (accessToken: string, groupId: string) => {
    //     try {
    //         // 8ff87d7d-c552-45f0-a634-19d223eba0b8
    //         // 8ff87d7d-c552-45f0-a634-19d223eba0b8
    //         // 2b4ecc82-8186-4a05-9480-035f6829e35e

    //         const membersResponse = await axios.get(
    //             // `${GRAPH_API_BASE_URL}/groups/${groupId}/members`, // Fetch members of the group
    //             // `${GRAPH_API_BASE_URL}/groups/8ff87d7d-c552-45f0-a634-19d223eba0b8/members`, // Fetch members of the group
    //             `${GRAPH_API_BASE_URL}/groups/2b4ecc82-8186-4a05-9480-035f6829e35e/members`, // Fetch members of the group
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${accessToken}`,
    //                 },
    //             }
    //         );

    //         localStorage.setItem(`raw_group_response${groupId}`, JSON.stringify(membersResponse)); // Store group members in local storage for each group

    //         const members = membersResponse.data.value.map((member: any) => ({
    //             id: member.id,
    //             displayName: member.displayName,
    //             mail: member.mail,
    //             userPrincipalName: member.userPrincipalName,
    //         }));

    //         setGroupMembers((prevMembers: any) => ({
    //             ...prevMembers,
    //             [groupId]: members, // Store members for the groupId
    //         }));

    //         localStorage.setItem(`groupMembers_${groupId}`, JSON.stringify(members)); // Store group members in local storage for each group
    //     } catch (error) {
    //         console.error(`Error fetching members for group ${groupId}: `, error);
    //     }
    // };

    // // Function for silent login
    // const silentLogin = async () => {
    //     const silentRequest = {
    //         scopes: ["User.Read", "Group.Read.All"], // Include group scope
    //         loginHint: account?.username || "",
    //     };
    //     console.log('flag-3')
    //     try {

    //         //console.log('silentRequest>>',silentRequest)
    //         const loginResponse = await instance.ssoSilent(silentRequest);
    //         //  console.log('loginResponse>>',loginResponse)
    //         const accessToken = loginResponse.accessToken;
    //         localStorage.setItem("accessToken", accessToken); // Store access token in local storage

    //         const decodedToken: any = jwtDecode(loginResponse.idToken);
    //         localStorage.setItem("decodedToken", JSON.stringify(decodedToken)); // Store decoded token

    //         // Fetch groups and members after login
    //         await fetchGroups(accessToken);

    //         navigate("/assessments");
    //     } catch (err) {
    //         if (err) {
    //             //    handleMicrosoftLogin(); // Fallback to interactive login if silent login fails
    //         } else {
    //             console.error("Silent Login Error: ", err);
    //         }
    //     }
    // };



    const groupSync = async (payload: any) => {
        try {
            const response: any = await groupSyncApi(payload);

            if (response?.status == 200) {
                return ''
            } else if (response?.status == 400) {
                setError(response?.data?.message || 'error');
            } else {
                setError(response?.data?.message || 'error');
            }

        } catch (error) {
            console.error("Error insert Departments:", error);
        }
    };



    useEffect(() => {
        if (account) {
            instance.handleRedirectPromise().then(async (loginResponse: any) => {
                if (loginResponse) {
                    setLoaderStatus(0)

                    // You can access the access token from the login response
                    // const accessToken = loginResponse.accessToken;
                    const accessToken = loginResponse.accessToken;
                    localStorage.setItem("accessToken", accessToken); // Store access token in local storage

                    const decodedToken: any = jwtDecode(loginResponse.idToken);
                    localStorage.setItem("decodedToken", JSON.stringify(decodedToken)); // Store decoded token

                    // Fetch groups and members after login
                    await fetchGroups(accessToken);

                    navigate("/assessments");

                    // Use the access token for API calls
                }
                else {
                    setLoaderStatus(1)
                }
            }).catch((error: any) => {
                console.error("Redirect error: ", error);
                setLoaderStatus(1)
            });
        }
        else {
            setLoaderStatus(1)
        }
    }, [account, instance]);

    return (
        <div className="mainWrap">
            <div className="loginPageWrapper">
                <div className="loginPageRight">

                    {
                        loaderStatus == 0 && (
                            <div className="pageLoader">
                                <span className="spinner"></span>
                            </div>
                        )
                    }
                    <div className="loginrightForm">
                        <div className="contentForm">
                            <div className="contentFormHead">
                                <a href="/">
                                    <img className="login-img" src="../../../assets/images/logo-vertical.png" alt="Evvo Logo" />
                                </a>
                                <h4>Login to Wearable Management System</h4>
                            </div>

                            <div className="contentFormBody">
                                <div className="form-group">
                                    <InputGroup>
                                        <InputGroup.Text id="basic-addon1" className="icon-email"></InputGroup.Text>
                                        <Form.Control placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                                    </InputGroup>
                                </div>
                                <div className="form-group">
                                    <InputGroup>
                                        <InputGroup.Text id="basic-addon2" className="icon-password"></InputGroup.Text>
                                        <Form.Control placeholder="******" aria-label="Password" type="password" />
                                        <InputGroup.Text id="basic-addon1" className="icon-password-lock cursor-pointer"></InputGroup.Text>
                                    </InputGroup>
                                </div>

                                <div className="forgotLink">
                                    <a href="">Forgot password?</a>
                                </div>
                                {/* 
                                {
                                    !loaderStatus ? */}

                                <div className="submitBtn-wrap">
                                    <Button variant="primary" className="w-100 text-start">Login <i className="icon-arrow-right"></i></Button>
                                    <Button variant="outline-secondary" className="w-100 text-start mt-4 logBtnSec" onClick={handleMicrosoftLogin}>
                                        <svg aria-hidden="true" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.5216 0.5H0V11.9067H11.5216V0.5Z" fill="#f25022"></path>
                                            <path d="M24.2418 0.5H12.7202V11.9067H24.2418V0.5Z" fill="#7fba00"></path>
                                            <path d="M11.5216 13.0933H0V24.5H11.5216V13.0933Z" fill="#00a4ef"></path>
                                            <path d="M24.2418 13.0933H12.7202V24.5H24.2418V13.0933Z" fill="#ffb900"></path>
                                        </svg>
                                        Log in with Microsoft
                                    </Button>
                                </div>

                                {/* // :
                                //     // <h4>Login... Kindly wait...</h4>
                                //     <div className="pageLoader">
                                //         <span className="spinner"></span>
                                //      </div>
                                // } */}
                                {/* // <div className="submitBtn-wrap">
                                //     <Button variant="primary" className="w-100 text-start">Login <i className="icon-arrow-right"></i></Button>
                                //     <Button variant="outline-secondary" className="w-100 text-start mt-4 logBtnSec" onClick={handleMicrosoftLogin}>
                                //         <svg aria-hidden="true" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                //             <path d="M11.5216 0.5H0V11.9067H11.5216V0.5Z" fill="#f25022"></path>
                                //             <path d="M24.2418 0.5H12.7202V11.9067H24.2418V0.5Z" fill="#7fba00"></path>
                                //             <path d="M11.5216 13.0933H0V24.5H11.5216V13.0933Z" fill="#00a4ef"></path>
                                //             <path d="M24.2418 13.0933H12.7202V24.5H24.2418V13.0933Z" fill="#ffb900"></path>
                                //         </svg>
                                //         Log in with Microsoft
                                //     </Button>
                                // </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
















// -------------------------- working azure login -------------

// import React, { useEffect, useState } from "react";
// import { useMsal, useAccount } from "@azure/msal-react";
// import { jwtDecode } from 'jwt-decode';
// import { InputGroup, Form, Button } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";

// const Login = () => {
//     const { instance, accounts, inProgress } = useMsal();
//     const navigate = useNavigate();
//     const account = useAccount(accounts[0] || {});
//     const [userInfo, setUserInfo] = useState<any>(null);
//     const [groups, setGroups] = useState<string[]>([]);

//     // Function to handle Microsoft login
//     const handleMicrosoftLogin = () => {
//         instance.loginRedirect({
//             scopes: ["openid", "profile", "User.Read", "Group.Read.All"],
//         }).catch((e) => console.error("Login Error: ", e));
//     };

//     // Function for silent login
//     const silentLogin = async () => {
//         const silentRequest = {
//             scopes: ["User.Read"],
//             loginHint: account?.username || "", // Use loginHint if available
//         };

//         try {
//             const loginResponse = await instance.ssoSilent(silentRequest);
//             localStorage.setItem("accessToken", JSON.stringify(loginResponse.accessToken));

//             const decodedToken: any = jwtDecode(loginResponse.idToken);
//             localStorage.setItem("decodedToken", JSON.stringify(decodedToken));
//             navigate("/assessments");
//         } catch (err) {
//             if (err) {
//                 // Fallback to interactive login if silent login fails
//                 handleMicrosoftLogin();
//             } else {
//                 console.error("Silent Login Error: ", err);
//             }
//         }
//     };

//     useEffect(() => {
//         // Check for existing accounts and perform silent login if an account is found
//         if (account) {
//             silentLogin();
//         }
//     }, [account, instance]);

//     return (
//         <div className="mainWrap">
//             <div className="loginPageWrapper">
//                 <div className="loginPageRight">
//                     <div className="loginrightForm">
//                         <div className="contentForm">
//                             <div className="contentFormHead">
//                                 <a href="/">
//                                     <img className="login-img" src="../../../assets/images/logo-vertical.png" alt="Evvo Logo" />
//                                 </a>
//                                 <h4>Login to Wearable Management System</h4>
//                             </div>

//                             <div className="contentFormBody">
//                                 <div className="form-group">
//                                     <InputGroup>
//                                         <InputGroup.Text id="basic-addon1" className="icon-email"></InputGroup.Text>
//                                         <Form.Control placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
//                                     </InputGroup>
//                                 </div>
//                                 <div className="form-group">
//                                     <InputGroup>
//                                         <InputGroup.Text id="basic-addon2" className="icon-password"></InputGroup.Text>
//                                         <Form.Control placeholder="******" aria-label="Password" type="password" />
//                                         <InputGroup.Text id="basic-addon1" className="icon-password-lock cursor-pointer"></InputGroup.Text>
//                                     </InputGroup>
//                                 </div>

//                                 <div className="forgotLink">
//                                     <a href="">Forgot password?</a>
//                                 </div>

//                                 <div className="submitBtn-wrap">
//                                     <Button variant="primary" className="w-100 text-start">Login <i className="icon-arrow-right"></i></Button>
//                                     <Button variant="outline-secondary" className="w-100 text-start mt-4 logBtnSec" onClick={handleMicrosoftLogin}>
//                                         <svg aria-hidden="true" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                             <path d="M11.5216 0.5H0V11.9067H11.5216V0.5Z" fill="#f25022"></path>
//                                             <path d="M24.2418 0.5H12.7202V11.9067H24.2418V0.5Z" fill="#7fba00"></path>
//                                             <path d="M11.5216 13.0933H0V24.5H11.5216V13.0933Z" fill="#00a4ef"></path>
//                                             <path d="M24.2418 13.0933H12.7202V24.5H24.2418V13.0933Z" fill="#ffb900"></path>
//                                         </svg>
//                                         Log in with Microsoft
//                                     </Button>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Login;


















// import React, { useEffect, useState } from "react";
// import { useMsal, useAccount } from "@azure/msal-react";
// import { jwtDecode } from 'jwt-decode';
// import { InputGroup, Form, Button } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { azureUserLogin } from '../../service/common.service';

// const Login = () => {
//     const { instance, accounts, inProgress } = useMsal();
//     const [userInfo, setUserInfo] = useState<any>(null);
//     const [groups, setGroups] = useState<string[]>([]);
//     const navigate = useNavigate();


//     const account = useAccount(accounts[0] || {});
//     const [apiData, setApiData] = useState(null);

//     const handleMicrosoftLogin = () => {
//         instance.loginRedirect({
//             scopes: ["openid", "profile", "User.Read", "Group.Read.All"],
//         }).catch((e) => console.error("Login Error: ", e));
//     };

//     useEffect(() => {
//         if (account) {
//             instance.acquireTokenSilent({
//                 scopes: ["User.Read"],
//                 account: account
//             }).then((response) => {
//                 // @ts-ignore
//                 if (response) {
//                     console.log(response.accessToken, '-<,response.accessToken');
//                     localStorage.setItem("accessToken", JSON.stringify(response.accessToken));

//                     const decodedToken: any = jwtDecode(response.idToken);

//                     // Store the decoded token and set user info
//                     localStorage.setItem("decodedToken", JSON.stringify(decodedToken));



//                     navigate("/assessments");
//                     // @ts-ignore
//                     // callMsGraph(response.accessToken).then((result: any) => setApiData(result));
//                 }
//             });
//         }
//     }, [account, instance]);

//     // if (accounts.length > 0) {
//     //     return (
//     //         <>
//     //             <span>There are currently {accounts.length} users signed in!</span>
//     //             {apiData && (<span>Data retreived from API: {JSON.stringify(apiData)}</span>)}
//     //         </>
//     //     );
//     // } else if (inProgress === "login") {
//     //     return <span>Login is currently in progress!</span>
//     // } else {
//     //     return <span>There are currently no users signed in!</span>
//     // }

//     return (
//         <div className="mainWrap">
//             <div className="loginPageWrapper">
//                 <div className="loginPageRight">
//                     <div className="loginrightForm">
//                         <div className="contentForm">
//                             <div className="contentFormHead">
//                                 <a href="/">
//                                     <img className="login-img" src="../../../assets/images/logo-vertical.png" alt="Evvo Logo" />
//                                 </a>
//                                 <h4>Login to Wearable Management System</h4>
//                             </div>

//                             <div className="contentFormBody">
//                                 <div className="form-group">
//                                     <InputGroup>
//                                         <InputGroup.Text id="basic-addon1" className="icon-email"></InputGroup.Text>
//                                         <Form.Control placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
//                                     </InputGroup>
//                                 </div>
//                                 <div className="form-group">
//                                     <InputGroup>
//                                         <InputGroup.Text id="basic-addon2" className="icon-password"></InputGroup.Text>
//                                         <Form.Control placeholder="******" aria-label="Password" type="password" />
//                                         <InputGroup.Text id="basic-addon1" className="icon-password-lock cursor-pointer"></InputGroup.Text>
//                                     </InputGroup>
//                                 </div>

//                                 <div className="forgotLink">
//                                     <a href="">Forgot password?</a>
//                                 </div>

//                                 <div className="submitBtn-wrap">
//                                     <Button variant="primary" className="w-100 text-start">Login <i className="icon-arrow-right"></i></Button>
//                                     <Button variant="outline-secondary" className="w-100 text-start mt-4 logBtnSec" onClick={handleMicrosoftLogin}>
//                                         <svg aria-hidden="true" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                             <path d="M11.5216 0.5H0V11.9067H11.5216V0.5Z" fill="#f25022"></path>
//                                             <path d="M24.2418 0.5H12.7202V11.9067H24.2418V0.5Z" fill="#7fba00"></path>
//                                             <path d="M11.5216 13.0933H0V24.5H11.5216V13.0933Z" fill="#00a4ef"></path>
//                                             <path d="M24.2418 13.0933H12.7202V24.5H24.2418V13.0933Z" fill="#ffb900"></path>
//                                         </svg>
//                                         Log in with Microsoft
//                                     </Button>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Login;


















// import React, { useEffect, useState } from "react";
// import { useMsal } from "@azure/msal-react";
// import { InteractionType } from "@azure/msal-browser";
// import { jwtDecode } from 'jwt-decode'; // Fix: jwtDecode should be imported without curly braces
// import { InputGroup, Form, Button } from "react-bootstrap";
// import { useNavigate } from "react-router-dom"; // Ensure you have react-router-dom installed
// import { azureUserLogin } from '../../service/common.service'

// const Login = () => {
//     const { instance, accounts, inProgress } = useMsal();
//     const [userInfo, setUserInfo] = useState<any>(null);
//     const [groups, setGroups] = useState<string[]>([]); // State to store group IDs
//     const navigate = useNavigate(); // Use navigate from React Router

//     // // Login with Microsoft handler
//     // const handleMicrosoftLogin = () => {
//     //     instance.loginRedirect({
//     //         scopes: ["openid", "profile", "User.Read", "Group.Read.All"],
//     //     }).catch((e) => console.error(e));
//     // };

//     const handleMicrosoftLogin = () => {
//         instance.loginRedirect({
//             scopes: ["openid", "profile", "User.Read", "Group.Read.All"],
//         }).catch((e) => console.error("Login Error: ", e)); // Log errors clearly
//     };



//     // Function to acquire token and fetch user details
//     // const fetchUserDetails = () => {
//     //     if (accounts.length > 0 && inProgress === "none") {
//     //         instance.acquireTokenSilent({
//     //             scopes: ["openid", "profile", "User.Read", "Group.Read.All"],
//     //             account: accounts[0],
//     //         }).then((response) => {
//     //             const decodedToken: any = jwtDecode(response.idToken);

//     //             // Store the decoded token and set user info
//     //             localStorage.setItem("decodedToken", JSON.stringify(decodedToken));

//     //             setUserInfo(decodedToken);

//     //             // Fetch user's groups from Microsoft Graph API
//     //             fetch(`https://graph.microsoft.com/v1.0/me/memberOf`, {
//     //                 headers: {
//     //                     Authorization: `Bearer ${response.accessToken}`,
//     //                 },
//     //             }).then((res) => res.json())
//     //                 .then((data: any) => {
//     //                     // Extract group IDs from the response
//     //                     const groupIds = data.value.map((group: any) => group.id);
//     //                     setGroups(groupIds);
//     //                     localStorage.setItem("groupIds", JSON.stringify(groupIds));

//     //                     // After fetching the groups, insert into the database via API
//     //                     storeUserData(decodedToken, groupIds);


//     //                     // After fetching the groups, redirect to assessments
//     //                     navigate("/assessments");
//     //                 })
//     //                 .catch((error) => {
//     //                     console.error("Error fetching groups: ", error);
//     //                 });
//     //         })
//     //             .catch((error) => {
//     //                 console.error("Token acquisition error: ", error);
//     //                 handleMicrosoftLogin(); // Fallback to login if token acquisition fails
//     //             });
//     //     }
//     // };

//     const fetchUserDetails = () => {
//         if (accounts.length > 0 && inProgress === "none") {
//             instance.acquireTokenSilent({
//                 scopes: ["openid", "profile", "User.Read", "Group.Read.All"],
//                 account: accounts[0],
//             }).then((response) => {
//                 const decodedToken: any = jwtDecode(response.idToken);

//                 localStorage.setItem("decodedToken", JSON.stringify(decodedToken));
//                 setUserInfo(decodedToken);

//                 fetch(`https://graph.microsoft.com/v1.0/me/memberOf`, {
//                     headers: {
//                         Authorization: `Bearer ${response.accessToken}`,
//                     },
//                 }).then((res) => res.json())
//                     .then((data: any) => {
//                         const groupIds = data.value.map((group: any) => group.id);
//                         setGroups(groupIds);
//                         localStorage.setItem("groupIds", JSON.stringify(groupIds));

//                         storeUserData(decodedToken, groupIds);
//                         navigate("/assessments"); // Only navigate after data fetch
//                     })
//                     .catch((error) => {
//                         console.error("Error fetching groups: ", error);
//                     });
//             })
//                 .catch((error) => {
//                     console.error("Token acquisition error: ", error);
//                     handleMicrosoftLogin(); // Fallback to login if token acquisition fails
//                 });
//         }
//     };

//     const storeUserData = async (decodedToken: any, groupIds: string[]) => {
//         const userData = {
//             tokenData: decodedToken,
//             groups: groupIds,
//         };

//         // Call the `apiRequest` function to send the user data
//         return await azureUserLogin(userData);
//     };


//     // Fetch user details only after Microsoft login
//     useEffect(() => {
//         if (accounts.length > 0 && inProgress === "none") {
//             fetchUserDetails();
//         }
//     }, [accounts, inProgress]);

//     return (
//         <div className="mainWrap">
//             <div className="loginPageWrapper">
//                 <div className="loginPageRight">
//                     <div className="loginrightForm">
//                         <div className="contentForm">
//                             <div className="contentFormHead">
//                                 <a href="/">
//                                     <img className="login-img" src="../../../assets/images/logo-vertical.png" alt="Evvo Logo" />
//                                 </a>
//                                 <h4>Login to Wearable Management System</h4>
//                             </div>

//                             <div className="contentFormBody">
//                                 {/* Regular Username/Password Login */}
//                                 <div className="form-group">
//                                     <InputGroup>
//                                         <InputGroup.Text id="basic-addon1" className="icon-email"></InputGroup.Text>
//                                         <Form.Control
//                                             placeholder="Username"
//                                             aria-label="Username"
//                                             aria-describedby="basic-addon1"
//                                         />
//                                     </InputGroup>
//                                 </div>
//                                 <div className="form-group">
//                                     <InputGroup>
//                                         <InputGroup.Text id="basic-addon1" className="icon-password"></InputGroup.Text>
//                                         <Form.Control
//                                             placeholder="******"
//                                             aria-label="Password"
//                                             aria-describedby="basic-addon2"
//                                             type="password"
//                                         />
//                                         <InputGroup.Text id="basic-addon1" className="icon-password-lock cursor-pointer"></InputGroup.Text>
//                                     </InputGroup>
//                                 </div>

//                                 <div className="forgotLink">
//                                     <a href="">Forgot password?</a>
//                                 </div>

//                                 {/* Submit button for regular login */}
//                                 <div className="submitBtn-wrap">
//                                     <Button variant="primary" className="w-100 text-start">
//                                         Login <i className="icon-arrow-right"></i>
//                                     </Button>

//                                     {/* Microsoft Login Button */}
//                                     <Button
//                                         variant="outline-secondary"
//                                         className="w-100 text-start mt-4 logBtnSec"
//                                         onClick={handleMicrosoftLogin}
//                                     >
//                                         <svg aria-hidden="true" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                             <path d="M11.5216 0.5H0V11.9067H11.5216V0.5Z" fill="#f25022"></path>
//                                             <path d="M24.2418 0.5H12.7202V11.9067H24.2418V0.5Z" fill="#7fba00"></path>
//                                             <path d="M11.5216 13.0933H0V24.5H11.5216V13.0933Z" fill="#00a4ef"></path>
//                                             <path d="M24.2418 13.0933H12.7202V24.5H24.2418V13.0933Z" fill="#ffb900"></path>
//                                         </svg>
//                                         Log in with Microsoft
//                                     </Button>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Login;




