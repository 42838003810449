import { Card, Col, Row } from "@govtechsg/sgds-react";
import { useState, useEffect } from 'react';
import SideNavBar from "../../components/Sidebar-left/sideBar";
import TopNav from "../../components/top-nav";
import Footer from "../../components/footer";
import { Dropdown, Form, Modal, Button, Table, Badge, Pagination } from "react-bootstrap";
import Select from 'react-select';
import axios from "axios";

const GRAPH_API_BASE_URL = "https://graph.microsoft.com/v1.0";

const options = [
  { value: '05', label: '05' },
  { value: '10', label: '10' }
];


const options2 = [
  { value: 'Office Admins', label: 'Office Admins' },
  { value: 'Office User', label: 'Office User' }
]

// Sample users data
const usersData = {
  'Office Admins': [
    { id: 1, name: 'Admin User 1' },
    { id: 2, name: 'Admin User 2' },
  ],
  'Office Users': [
    { id: 3, name: 'User 1' },
    { id: 4, name: 'User 2' },
  ],
};

const UserManagement = () => {
  const [selectedGroup, setSelectedGroup] = useState<any>(null);
  const [groups, setGroups] = useState<any[]>([]);
  const [groupMembers, setGroupMembers] = useState<any>({});

  const handleGroupChange = (selectedOption: any) => {
    setSelectedGroup(selectedOption);
  };

  // Function to fetch group details
  const fetchGroups = async (accessToken: string) => {
    try {
      const groupResponse = await axios.get(
        `${GRAPH_API_BASE_URL}/me/memberOf`, // Fetch user's group memberships
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const groupsData = groupResponse.data.value.map((group: any) => ({
        id: group.id,
        displayName: group.displayName,
        mail: group.mail,
      }));

      console.log(groupsData, '-<,groupsData');

      setGroups(groupsData);

      // Set default selected group to the first group if available
      if (groupsData.length > 0) {
        const defaultGroup = groupsData[0]; // Select the first group as default
        setSelectedGroup({ value: defaultGroup.id, label: defaultGroup.displayName });
        fetchGroupMembers(accessToken, defaultGroup.id); // Fetch members for the default group
      }

      // Fetch members for each group
      groupsData.forEach((group: any) => {
        fetchGroupMembers(accessToken, group.id); // Fetch group members
      });
    } catch (error) {
      console.error("Error fetching groups: ", error);
    }
  };

  // Function to fetch group members by groupId
  const fetchGroupMembers = async (accessToken: string, groupId: string) => {
    try {
      const membersResponse = await axios.get(
        `${GRAPH_API_BASE_URL}/groups/${groupId}/members`, // Fetch members of the group
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const members = membersResponse.data.value.map((member: any) => ({
        id: member.id,
        displayName: member.displayName,
        mail: member.mail,
        userPrincipalName: member.userPrincipalName,
      }));

      setGroupMembers((prevMembers: any) => ({
        ...prevMembers,
        [groupId]: members, // Store members for the groupId
      }));
    } catch (error) {
      console.error(`Error fetching members for group ${groupId}: `, error);
    }
  };

  // Fetch groups when the component mounts
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      fetchGroups(accessToken);
    }
  }, []);

  return (
    <>
      <TopNav />
      <div className="d-flex align-items-stretch">
        <SideNavBar />
        <div className="page-content pb-4">
          <div className="bredcrumb">
            <ul>
              <li><a href="#">Home</a></li>
              <li>User Management</li>
            </ul>
          </div>
          <section className="blockWrap no-padding-bottom p-0">
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between border-bottom-0">
                <div className="cardTitle mx-w-580">
                  <h5>User Management</h5>
                  <p>User management and role assignment for Office Admins and Field officers</p>
                </div>
                <a href="create-roles" className="btn btn-primary mn-w-148">Add New User</a>
              </div>
              <div className="card-body pt-0">
                <Row>
                  <Col lg="3">
                    <div className="userCountBlock">
                      <div className="cont">
                        <div className="label">Total User</div>
                        <div className="count">234</div>
                      </div>
                      <div className="icon purple"><i className="icon-uesr-outline"></i></div>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="userCountBlock">
                      <div className="cont">
                        <div className="label">Active Users</div>
                        <div className="count">198</div>
                      </div>
                      <div className="icon success"><i className="icon-uesr-outline"></i></div>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="userCountBlock">
                      <div className="cont">
                        <div className="label">In-Active Users</div>
                        <div className="count">2</div>
                      </div>
                      <div className="icon danger"><i className="icon-uesr-outline"></i></div>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="userCountBlock">
                      <div className="cont">
                        <div className="label">Pending Users</div>
                        <div className="count">4</div>
                      </div>
                      <div className="icon pending"><i className="icon-uesr-outline"></i></div>
                    </div>
                  </Col>
                </Row>

                <div className="card-tableFilter">
                  <div className="pageFilter">
                    <label>Show</label>
                    <div className="custom-select-wrap">
                      <Select className="selectpicker" placeholder="05" options={options} />
                    </div>
                  </div>
                  <div className="tableFilterWrap">
                    <div className="custom-select-wrap">
                      <Select
                        className="selectpicker"
                        placeholder="Select Group"
                        options={groups.map(group => ({ value: group.id, label: group.displayName }))}
                        onChange={handleGroupChange}
                        value={selectedGroup} // Set the default selected group
                      />
                    </div>
                    <div className="searchFilter">
                      <div className="searchBar">
                        <input type="text" placeholder="Search By User" value="" />
                        <button className="searchBtn"><i className="icon-search-sign"></i></button>
                      </div>
                    </div>
                    <div className="actionWrap">
                      <Dropdown className="d-inline" autoClose="outside">
                        <Dropdown.Toggle id="dropdown-autoclose-outside">
                          <i className="icon-lines-h"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="filterDropdown">
                          <div className="dropdown-head">
                            <span className="sub-title dropdown-title">Filter Users</span>
                            <div className="resetPassword">
                              <span className="text">Reset Filter</span>
                              <button className="dropClose"><i className="icon-popup-cross"></i></button>
                            </div>
                          </div>
                          <div className="dropdown-body">
                            <div className="form-group">
                              <div className="custom-select-wrap">
                                <Select className="selectpicker" placeholder="Select Role" options={options2} />
                              </div>
                            </div>
                            <div className="submitWrap">
                              <button id="filter-data" className="btn btn-sm btn-primary">Filter User</button>
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>

                <div className="card-table">
                  <Table>
                    <thead>
                      <tr>
                        <th>USER</th>
                        <th>ROLE</th>
                        <th>CREATED DATE</th>
                        <th>LAST LOGIN</th>
                        <th>STATUS</th>
                        <th>ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {groups.length > 0 && selectedGroup?.value && (
                        groupMembers[selectedGroup.value]?.length > 0 ? (
                          groupMembers[selectedGroup.value].map((member: any) => (
                            <tr key={member.id}>
                              <td>
                                <div className="profilBlock">
                                  <div className="img">
                                    <img src="../../assets/images/user.png" alt="User" />
                                  </div>
                                  <div className="cont">
                                    <h5>{member.displayName || 'No Name'}</h5>
                                    <span className="email">{member.mail || 'Email not available'}</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="profilBlock">
                                  <div className="img purple">
                                    <i className="icon-user-outline"></i>
                                  </div>
                                  <div className="cont">
                                    <h5>Sub Admin</h5>
                                  </div>
                                </div>
                              </td>
                              <td>06/11/2024</td>
                              <td>14 Sep 2023, 8:43 PM</td>
                              <td>
                                <Badge bg="success" className="mn-w-62">Active</Badge>
                              </td>
                              <td>
                                <div className="actionWrap">
                                  <div className="action">
                                    <Button><i className="icon-eye"></i></Button>
                                    <Button><i className="icon-delete"></i></Button>
                                  </div>
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <i className="icon-dots-v"></i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                      <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                      <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6}>No users found in this group.</td>
                          </tr>
                        )
                      )}



                    </tbody>
                  </Table>
                </div>

                <div className="mt-4 mb-2 justify-content-between d-flex align-items-center">
                  <div className="showingContNo">Showing 1 to 10 of 50 entries</div>
                  <Pagination className="mb-0">
                    <Pagination.Prev className="prev" />
                    <Pagination.Item>{1}</Pagination.Item>
                    <Pagination.Item active>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Ellipsis />
                    <Pagination.Next className="next" />
                  </Pagination>
                </div>

                {/* Users List based on selected group */}
                {/* {groups.length > 0 && (
                  <div className="user-list">
                    <h5>Users in {selectedGroup?.label}:</h5>
                    <ul>
                      {groupMembers[selectedGroup?.value]?.length > 0 ? (groupMembers[selectedGroup.value].map((member: any) => (
                        <li key={member.id}>{member.displayName}</li>
                      ))
                      ) : (
                        <li>No users found in this group.</li>
                      )}
                    </ul>
                  </div>
                )} */}

              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default UserManagement;

















// import { Card, Col, Row } from "@govtechsg/sgds-react";
// import { useState, useEffect } from 'react';
// import SideNavBar from "../../components/Sidebar-left/sideBar";
// import TopNav from "../../components/top-nav";
// import Footer from "../../components/footer";
// import Select from 'react-select';
// import axios from "axios";

// const GRAPH_API_BASE_URL = "https://graph.microsoft.com/v1.0";

// const options = [
//   { value: '05', label: '05' },
//   { value: '10', label: '10' }
// ];

// // Sample users data
// const usersData = {
//   'Office Admins': [
//     { id: 1, name: 'Admin User 1' },
//     { id: 2, name: 'Admin User 2' },
//   ],
//   'Office Users': [
//     { id: 3, name: 'User 1' },
//     { id: 4, name: 'User 2' },
//   ],
// };

// const UserManagement = () => {
//   const [selectedGroup, setSelectedGroup] = useState<any>(null);
//   const [groups, setGroups] = useState<any[]>([]);
//   const [groupMembers, setGroupMembers] = useState<any>({});

//   const handleGroupChange = (selectedOption: any) => {
//     setSelectedGroup(selectedOption);
//   };

//   // Function to fetch group details
//   const fetchGroups = async (accessToken: string) => {
//     try {
//       const groupResponse = await axios.get(
//         `${GRAPH_API_BASE_URL}/me/memberOf`, // Fetch user's group memberships
//         {
//           headers: {
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       const groupsData = groupResponse.data.value.map((group: any) => ({
//         id: group.id,
//         displayName: group.displayName,
//         mail: group.mail,
//       }));

//       console.log(groupsData, '-<,groupsData');

//       setGroups(groupsData);

//       // Set default selected group to the first group if available
//       if (groupsData.length > 0) {
//         const defaultGroup = groupsData[0]; // Select the first group as default
//         setSelectedGroup({ value: defaultGroup.id, label: defaultGroup.displayName });
//         fetchGroupMembers(accessToken, defaultGroup.id); // Fetch members for the default group
//       }

//       // Fetch members for each group
//       groupsData.forEach((group: any) => {
//         fetchGroupMembers(accessToken, group.id); // Fetch group members
//       });
//     } catch (error) {
//       console.error("Error fetching groups: ", error);
//     }
//   };

//   // Function to fetch group members by groupId
//   const fetchGroupMembers = async (accessToken: string, groupId: string) => {
//     try {
//       const membersResponse = await axios.get(
//         `${GRAPH_API_BASE_URL}/groups/${groupId}/members`, // Fetch members of the group
//         {
//           headers: {
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       const members = membersResponse.data.value.map((member: any) => ({
//         id: member.id,
//         displayName: member.displayName,
//         mail: member.mail,
//         userPrincipalName: member.userPrincipalName,
//       }));

//       setGroupMembers((prevMembers: any) => ({
//         ...prevMembers,
//         [groupId]: members, // Store members for the groupId
//       }));
//     } catch (error) {
//       console.error(`Error fetching members for group ${groupId}: `, error);
//     }
//   };

//   // Fetch groups when the component mounts
//   useEffect(() => {
//     const accessToken = localStorage.getItem("accessToken");
//     if (accessToken) {
//       fetchGroups(accessToken);
//     }
//   }, []);

//   return (
//     <>
//       <TopNav />
//       <div className="d-flex align-items-stretch">
//         <SideNavBar />
//         <div className="page-content pb-4">
//           <div className="bredcrumb">
//             <ul>
//               <li><a href="#">Home</a></li>
//               <li>User Management</li>
//             </ul>
//           </div>
//           <section className="blockWrap no-padding-bottom p-0">
//             <div className="card">
//               <div className="card-header d-flex align-items-center justify-content-between border-bottom-0">
//                 <div className="cardTitle mx-w-580">
//                   <h5>User Management</h5>
//                   <p>User management and role assignment for Office Admins and Field officers</p>
//                 </div>
//                 <a href="create-roles" className="btn btn-primary mn-w-148">Add New User</a>
//               </div>
//               <div className="card-body pt-0">
//                 <Row>
//                   <Col lg="3">
//                     <div className="userCountBlock">
//                       <div className="cont">
//                         <div className="label">Total User</div>
//                         <div className="count">234</div>
//                       </div>
//                       <div className="icon purple"><i className="icon-uesr-outline"></i></div>
//                     </div>
//                   </Col>
//                   <Col lg="3">
//                     <div className="userCountBlock">
//                       <div className="cont">
//                         <div className="label">Active Users</div>
//                         <div className="count">198</div>
//                       </div>
//                       <div className="icon success"><i className="icon-uesr-outline"></i></div>
//                     </div>
//                   </Col>
//                   <Col lg="3">
//                     <div className="userCountBlock">
//                       <div className="cont">
//                         <div className="label">In-Active Users</div>
//                         <div className="count">2</div>
//                       </div>
//                       <div className="icon danger"><i className="icon-uesr-outline"></i></div>
//                     </div>
//                   </Col>
//                   <Col lg="3">
//                     <div className="userCountBlock">
//                       <div className="cont">
//                         <div className="label">Pending Users</div>
//                         <div className="count">4</div>
//                       </div>
//                       <div className="icon pending"><i className="icon-uesr-outline"></i></div>
//                     </div>
//                   </Col>
//                 </Row>
//                 <div className="card-table">
//                   <div className="card-tableFilter">
//                     <div className="pageFilter">
//                       <label>Show</label>
//                       <div className="custom-select-wrap">
//                         <Select className="selectpicker" placeholder="05" options={options} />
//                       </div>
//                     </div>
//                     <div className="tableFilterWrap">
//                       <div className="custom-select-wrap">
//                         <Select
//                           className="selectpicker"
//                           placeholder="Select Group"
//                           options={groups.map(group => ({ value: group.id, label: group.displayName }))}
//                           onChange={handleGroupChange}
//                           value={selectedGroup} // Set the default selected group
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 {/* Users List based on selected group */}
//                 {groups.length > 0 && (
//                   <div className="user-list">
//                     <h5>Users in {selectedGroup?.label}:</h5>
//                     <ul>
//                       {groupMembers[selectedGroup?.value]?.length > 0 ? (
//                         groupMembers[selectedGroup.value].map((member: any) => (
//                           <li key={member.id}>{member.displayName}</li>
//                         ))
//                       ) : (
//                         <li>No users found in this group.</li>
//                       )}
//                     </ul>
//                   </div>
//                 )}

//               </div>
//             </div>
//           </section>
//           <Footer />
//         </div>
//       </div>
//     </>
//   );
// };

// export default UserManagement;












// import { Card, Col, Row } from "@govtechsg/sgds-react";
// import { useState, useEffect } from 'react';
// import SideNavBar from "../../components/Sidebar-left/sideBar";
// import TopNav from "../../components/top-nav";
// import Footer from "../../components/footer";
// import Select from 'react-select';
// import axios from "axios";

// const GRAPH_API_BASE_URL = "https://graph.microsoft.com/v1.0";

// const options = [
//   { value: '05', label: '05' },
//   { value: '10', label: '10' }
// ];

// // Sample users data
// const usersData = {
//   'Office Admins': [
//     { id: 1, name: 'Admin User 1' },
//     { id: 2, name: 'Admin User 2' },
//   ],
//   'Office Users': [
//     { id: 3, name: 'User 1' },
//     { id: 4, name: 'User 2' },
//   ],
// };

// const UserManagement = () => {
//   const [selectedGroup, setSelectedGroup] = useState<any>(null);
//   const [groups, setGroups] = useState<any[]>([]);
//   const [groupMembers, setGroupMembers] = useState<any>({});

//   const handleGroupChange = (selectedOption: any) => {
//     setSelectedGroup(selectedOption);
//   };

//   // Function to fetch group details
//   const fetchGroups = async (accessToken: string) => {
//     try {
//       const groupResponse = await axios.get(
//         `${GRAPH_API_BASE_URL}/me/memberOf`, // Fetch user's group memberships
//         {
//           headers: {
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       const groupsData = groupResponse.data.value.map((group: any) => ({
//         id: group.id,
//         displayName: group.displayName,
//         mail: group.mail,
//       }));

//       console.log(groupsData, '-<,groupsData');


//       setGroups(groupsData);

//       // Fetch members for each group
//       groupsData.forEach((group: any) => {
//         fetchGroupMembers(accessToken, group.id); // Fetch group members
//       });
//     } catch (error) {
//       console.error("Error fetching groups: ", error);
//     }
//   };

//   // Function to fetch group members by groupId
//   const fetchGroupMembers = async (accessToken: string, groupId: string) => {
//     try {
//       const membersResponse = await axios.get(
//         `${GRAPH_API_BASE_URL}/groups/${groupId}/members`, // Fetch members of the group
//         {
//           headers: {
//             Authorization: `Bearer ${accessToken}`,
//           },
//         }
//       );

//       const members = membersResponse.data.value.map((member: any) => ({
//         id: member.id,
//         displayName: member.displayName,
//         mail: member.mail,
//         userPrincipalName: member.userPrincipalName,
//       }));

//       setGroupMembers((prevMembers: any) => ({
//         ...prevMembers,
//         [groupId]: members, // Store members for the groupId
//       }));
//     } catch (error) {
//       console.error(`Error fetching members for group ${groupId}: `, error);
//     }
//   };

//   // Fetch groups when the component mounts
//   useEffect(() => {
//     const accessToken = localStorage.getItem("accessToken");
//     if (accessToken) {
//       fetchGroups(accessToken);
//     }
//   }, []);

//   return (
//     <>
//       <TopNav />
//       <div className="d-flex align-items-stretch">
//         <SideNavBar />
//         <div className="page-content pb-4">
//           <div className="bredcrumb">
//             <ul>
//               <li><a href="#">Home</a></li>
//               <li>User Management</li>
//             </ul>
//           </div>
//           <section className="blockWrap no-padding-bottom p-0">
//             <div className="card">
//               <div className="card-header d-flex align-items-center justify-content-between border-bottom-0">
//                 <div className="cardTitle mx-w-580">
//                   <h5>User Management</h5>
//                   <p>User management and role assignment for Office Admins and Field officers</p>
//                 </div>
//                 <a href="create-roles" className="btn btn-primary mn-w-148">Add New User</a>
//               </div>
//               <div className="card-body pt-0">
//                 <Row>
//                   <Col lg="3">
//                     <div className="userCountBlock">
//                       <div className="cont">
//                         <div className="label">Total User</div>
//                         <div className="count">234</div>
//                       </div>
//                       <div className="icon purple"><i className="icon-uesr-outline"></i></div>
//                     </div>
//                   </Col>
//                   <Col lg="3">
//                     <div className="userCountBlock">
//                       <div className="cont">
//                         <div className="label">Active Users</div>
//                         <div className="count">198</div>
//                       </div>
//                       <div className="icon success"><i className="icon-uesr-outline"></i></div>
//                     </div>
//                   </Col>
//                   <Col lg="3">
//                     <div className="userCountBlock">
//                       <div className="cont">
//                         <div className="label">In-Active Users</div>
//                         <div className="count">2</div>
//                       </div>
//                       <div className="icon danger"><i className="icon-uesr-outline"></i></div>
//                     </div>
//                   </Col>
//                   <Col lg="3">
//                     <div className="userCountBlock">
//                       <div className="cont">
//                         <div className="label">Pending Users</div>
//                         <div className="count">4</div>
//                       </div>
//                       <div className="icon pending"><i className="icon-uesr-outline"></i></div>
//                     </div>
//                   </Col>
//                 </Row>
//                 <div className="card-table">
//                   <div className="card-tableFilter">
//                     <div className="pageFilter">
//                       <label>Show</label>
//                       <div className="custom-select-wrap">
//                         <Select className="selectpicker" placeholder="05" options={options} />
//                       </div>
//                     </div>
//                     <div className="tableFilterWrap">
//                       <div className="custom-select-wrap">
//                         <Select
//                           className="selectpicker"
//                           placeholder="Select Group"
//                           options={groups.map(group => ({ value: group.id, label: group.displayName }))}
//                           onChange={handleGroupChange}
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 {/* Users List based on selected group */}
//                 {groups.length > 0 && (
//                   <div className="user-list">
//                     <h5>Users in {selectedGroup?.label}:</h5>
//                     <ul>
//                       {groupMembers[selectedGroup?.value]?.length > 0 ? (
//                         groupMembers[selectedGroup.value].map((member: any) => (
//                           <li key={member.id}>{member.displayName}</li>
//                         ))
//                       ) : (
//                         <li>No users found in this group.</li>
//                       )}
//                     </ul>
//                   </div>
//                 )}

//               </div>
//             </div>
//           </section>
//           <Footer />
//         </div>
//       </div>
//     </>
//   );
// };

// export default UserManagement;











// import { Card, Col, Row } from "@govtechsg/sgds-react";
// import { useState } from 'react';
// import SideNavBar from "../../components/Sidebar-left/sideBar";
// import TopNav from "../../components/top-nav";
// import Footer from "../../components/footer";
// import { Dropdown, Form, Modal, Button, Badge } from "react-bootstrap";
// import Select from 'react-select';


// const options = [
//   { value: '05', label: '05' },
//   { value: '10', label: '10' }
// ]

// const options2 = [
//   { value: 'Office Admins', label: 'Office Admins' },
//   { value: 'Office User', label: 'Office User' }
// ]




// const UserManagement = () => {

//   const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);



//   return (
//     <>
//       <TopNav />
//       <div className="d-flex align-items-stretch">
//         <SideNavBar />
//         <div className="page-content pb-4">
//           <div className="bredcrumb">
//             <ul>
//               <li><a href="#">Home</a></li>
//               <li>User Management</li>
//             </ul>
//           </div>
//           <section className="blockWrap no-padding-bottom p-0">
//             <div className="card">
//               <div className="card-header d-flex align-items-center justify-content-between border-bottom-0">
//                 <div className="cardTitle mx-w-580">
//                   <h5>User Management</h5>
//                   <p>User management and role assignment for Office Admins and Field officers</p>
//                 </div>

//                 <a href="create-roles" className="btn btn-primary mn-w-148">Add New User</a>
//               </div>

//               <div className="card-body pt-0">


//                 <Row>
//                   <Col lg="3">
//                     <div className="userCountBlock">
//                       <div className="cont">
//                         <div className="label">Total User</div>
//                         <div className="count">234</div>
//                       </div>
//                       <div className="icon purple"><i className="icon-uesr-outline"></i></div>
//                     </div>
//                   </Col>
//                   <Col lg="3">
//                     <div className="userCountBlock">
//                       <div className="cont">
//                         <div className="label">Active Users</div>
//                         <div className="count">198</div>
//                       </div>
//                       <div className="icon success"><i className="icon-uesr-outline"></i></div>
//                     </div>
//                   </Col>

//                   <Col lg="3">
//                     <div className="userCountBlock">
//                       <div className="cont">
//                         <div className="label">In-Active Users</div>
//                         <div className="count">2</div>
//                       </div>
//                       <div className="icon danger"><i className="icon-uesr-outline"></i></div>
//                     </div>
//                   </Col>

//                   <Col lg="3">
//                     <div className="userCountBlock">
//                       <div className="cont">
//                         <div className="label">Pending Users</div>
//                         <div className="count">4</div>
//                       </div>
//                       <div className="icon pending"><i className="icon-uesr-outline"></i></div>
//                     </div>
//                   </Col>
//                 </Row>


//                 <div className="card-table">
//                   <div className="card-tableFilter">
//                     <div className="pageFilter">
//                       <label>Show</label>
//                       <div className="custom-select-wrap">
//                         <Select className="selectpicker" placeholder="05" options={options} />
//                       </div>
//                     </div>
//                     <div className="tableFilterWrap">
//                       <div className="custom-select-wrap">
//                         <Select className="selectpicker" placeholder="Select Role" options={options2} />
//                       </div>
//                       <div className="searchFilter">
//                         <div className="searchBar">
//                           <input type="text" placeholder="Search by assessment" value="" />
//                           <button className="searchBtn"><i className="icon-search-sign"></i></button>
//                         </div>
//                       </div>
//                     </div>

//                   </div>
//                 </div>





//               </div>




//             </div>


//           </section>

//           <Footer />
//         </div>
//       </div>

//       <Modal className="mw-1080" show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Edit Role</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="pb-0">
//           <Row>
//             <Col lg="6">
//               <div className="form-group d-light">
//                 <label>Role Title</label>
//                 <input type="text" value="Sub Admin" placeholder="Enter Role Title" />
//               </div>
//             </Col>

//             <Col lg="6">
//               <div className="form-group d-light">
//                 <label>Role Description</label>
//                 <input type="text" value="Mange the Health data, Trainings, Events and User Management" placeholder="Enter Role Description" />
//               </div>
//             </Col>
//           </Row>


//           <div className="componentBlock">
//             <div className="componentHeader">
//               <div className="title">
//                 <h5>Module Access</h5>
//               </div>
//               <div className="action">
//                 <Select className="selectpicker" placeholder="Select role type" options={options} />
//               </div>
//             </div>
//             <div className="componentBody">
//               <Row>
//                 <Col lg="4">
//                   <div className="roleNameBlock">
//                     <div className="roleNameBlockhead">
//                       <h6>Dashboard</h6>
//                       <Form.Check type="switch" id="custom-switch" label="Off" />
//                     </div>
//                     <div className="roleNameBlockBody">
//                       <div className="inline-fields-wrap">
//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="Create" />
//                           <label>Create</label>
//                         </div>

//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="View" />
//                           <label>View</label>
//                         </div>

//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="Edit" />
//                           <label>Edit</label>
//                         </div>

//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="Delete" />
//                           <label>Delete</label>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </Col>
//                 <Col lg="4">
//                   <div className="roleNameBlock">
//                     <div className="roleNameBlockhead">
//                       <h6>Monitoring & Alerts</h6>
//                       <Form.Check type="switch" id="alerts-switch" label="Off" />
//                     </div>
//                     <div className="roleNameBlockBody">
//                       <div className="inline-fields-wrap">
//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="Create" />
//                           <label>Create</label>
//                         </div>

//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="View" />
//                           <label>View</label>
//                         </div>

//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="Edit" />
//                           <label>Edit</label>
//                         </div>

//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="Delete" />
//                           <label>Delete</label>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </Col>
//                 <Col lg="4">
//                   <div className="roleNameBlock">
//                     <div className="roleNameBlockhead">
//                       <h6>Health Data</h6>
//                       <Form.Check type="switch" id="health-data-switch" label="Off" />
//                     </div>
//                     <div className="roleNameBlockBody">
//                       <div className="inline-fields-wrap">
//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="Create" />
//                           <label>Create</label>
//                         </div>

//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="View" />
//                           <label>View</label>
//                         </div>

//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="Edit" />
//                           <label>Edit</label>
//                         </div>

//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="Delete" />
//                           <label>Delete</label>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </Col>
//                 <Col lg="4">
//                   <div className="roleNameBlock">
//                     <div className="roleNameBlockhead">
//                       <h6>Training & Events</h6>
//                       <Form.Check type="switch" id="training-events-switch" label="Off" />
//                     </div>
//                     <div className="roleNameBlockBody">
//                       <div className="inline-fields-wrap">
//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="Create" />
//                           <label>Create</label>
//                         </div>

//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="View" />
//                           <label>View</label>
//                         </div>

//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="Edit" />
//                           <label>Edit</label>
//                         </div>

//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="Delete" />
//                           <label>Delete</label>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </Col>
//                 <Col lg="4">
//                   <div className="roleNameBlock">
//                     <div className="roleNameBlockhead">
//                       <h6>User Managements</h6>
//                       <Form.Check type="switch" id="user-managements-switch" label="Off" />
//                     </div>
//                     <div className="roleNameBlockBody">
//                       <div className="inline-fields-wrap">
//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="Create" />
//                           <label>Create</label>
//                         </div>

//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="View" />
//                           <label>View</label>
//                         </div>

//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="Edit" />
//                           <label>Edit</label>
//                         </div>

//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="Delete" />
//                           <label>Delete</label>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </Col>
//                 <Col lg="4">
//                   <div className="roleNameBlock">
//                     <div className="roleNameBlockhead">
//                       <h6>Assessments</h6>
//                       <Form.Check type="switch" id="assessments-switch" label="Off" />
//                     </div>
//                     <div className="roleNameBlockBody">
//                       <div className="inline-fields-wrap">
//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="Create" />
//                           <label>Create</label>
//                         </div>

//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="View" />
//                           <label>View</label>
//                         </div>

//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="Edit" />
//                           <label>Edit</label>
//                         </div>

//                         <div className="custom-check-field">
//                           <input type="checkbox" name="Dashboard" value="Delete" />
//                           <label>Delete</label>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </Col>
//               </Row>
//             </div>
//           </div>



//         </Modal.Body>
//         <Modal.Footer className="bt-0 pt-3 pb-4">
//           <Button variant="primary" className="m-0" onClick={handleClose}>Save Chnages</Button>

//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default UserManagement;
