import { Card, Col, Row } from "@govtechsg/sgds-react";
import { useState } from 'react';
import SideNavBar from "../../components/Sidebar-left/sideBar";
import TopNav from "../../components/top-nav";
import Footer from "../../components/footer";
import { Dropdown, Pagination, Modal, Button, Tab, Tabs, Toast, ToastContainer, ProgressBar} from "react-bootstrap";





const CreateAssessments = () => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showA, setShowA] = useState(true);
  const toggleShowA = () => setShowA(!showA);

  return (
    <>
      <TopNav />
      <div className="d-flex align-items-stretch">
        <SideNavBar />
        <div className="page-content pb-4">
          <div className="bredcrumb">
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="#">Assessment</a></li>
              <li>Create New Assessment</li>
            </ul>
          </div>
          <section className="blockWrap no-padding-bottom p-0">
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center ">
                  <div className="cardTitle">
                    <div className="label">Assessments</div>
                    <h5>Mental health monitoring</h5>
                  </div>

                  <div className="cardTitle">
                  <div className="label">Team</div>
                    <h5>Civil Squads</h5>
                  </div>
                </div>
                

                <button className="btn btn-outline-primary">Save Assessment</button>
              </div>

              <div className="card-body pt-0">

                <div className="themeTabs">
                  <Tabs defaultActiveKey="add-edit" id="uncontrolled-tab-example">
                    <Tab eventKey="add-edit" title="Add & Edit">
                      <div className="addEditBlockWrap">
                        <div className="addEditBlockFlex">
                          <div className="title">1. How would you rate your overall mood today?</div>
                          <div className="action">
                            <Button variant="outline-primary"><i className="icon-edit"></i></Button>
                            <Button variant="outline-primary"><i className="icon-delete"></i></Button>
                          </div>
                        </div>
                      </div>
                      <div className="addEditBlockWrap">
                        <div className="addEditBlockFlex">
                          <div className="title">2. How would you rate your overall mood today?</div>
                          <div className="action">
                            <Button variant="outline-primary"><i className="icon-edit"></i></Button>
                            <Button variant="outline-primary"><i className="icon-delete"></i></Button>
                          </div>
                        </div>
                      </div>
                      <div className="addEditBlockWrap">
                        <div className="addEditBlockFlex">
                          <div className="title">3. How would you rate your overall mood today?</div>
                          <div className="action">
                            <Button variant="outline-primary"><i className="icon-edit"></i></Button>
                            <Button variant="outline-primary"><i className="icon-delete"></i></Button>
                          </div>
                        </div>
                      </div>

                      <div className="text-end mt-4 mb-2">
                        <Button variant="primary" onClick={handleShow}>Add New Questionnaire</Button>
                      </div>
                    </Tab>
                    <Tab eventKey="questionnaire" title="View Questionnaire">

                    <div className="viewQAWrap">
                    <div className="addEditBlockWrap pe-4 ps-4 pt-3 pb-3">
                        <div className="addEditBlockFlex">
                          <div className="title fw-700">1. How would you rate your overall mood today?</div>
                          <div className="action">
                            <Button variant="outline-primary"><i className="icon-edit"></i></Button>
                            <Button variant="outline-primary"><i className="icon-delete"></i></Button>
                          </div>
                        </div>

                        <div className="slAnswerWrap">
                          <label>Select any one answer</label>
                          <div className="inline-fields-wrap">
                                <div className="custom-check-field custom-radio">
                                  <input type="radio" name="rate" value="Very Good" />
                                  <label>Very Good</label>
                                </div>

                                <div className="custom-check-field custom-radio">
                                  <input type="radio" name="rate" value="Good" />
                                  <label>Good</label>
                                </div>

                                <div className="custom-check-field custom-radio">
                                  <input type="radio" name="rate" value="Neutral" />
                                  <label>Neutral</label>
                                </div>

                                <div className="custom-check-field custom-radio">
                                  <input type="radio" name="rate" value="Bad" />
                                  <label>Bad</label>
                                </div>

                          </div>
                        </div>
                      </div>


                      <div className="addEditBlockWrap pe-4 ps-4 pt-3 pb-3">
                        <div className="addEditBlockFlex">
                          <div className="title fw-700">2. How often did you feel stressed today?</div>
                          <div className="action">
                            <Button variant="outline-primary"><i className="icon-edit"></i></Button>
                            <Button variant="outline-primary"><i className="icon-delete"></i></Button>
                          </div>
                        </div>

                        <div className="slAnswerWrap">
                          <label>Select any one answer</label>
                          <div className="inline-fields-wrap">
                                <div className="custom-check-field custom-radio">
                                  <input type="radio" name="rate" value="Not at all" />
                                  <label>Not at all</label>
                                </div>

                                <div className="custom-check-field custom-radio">
                                  <input type="radio" name="rate" value="A little" />
                                  <label>A little</label>
                                </div>

                                <div className="custom-check-field custom-radio">
                                  <input type="radio" name="rate" value="Sometimes" />
                                  <label>Sometimes</label>
                                </div>

                                <div className="custom-check-field custom-radio">
                                  <input type="radio" name="rate" value="Often" />
                                  <label>Often</label>
                                </div>
                          </div>
                        </div>
                      </div>


                      <div className="addEditBlockWrap pe-4 ps-4 pt-3 pb-3">
                        <div className="addEditBlockFlex">
                          <div className="title fw-700">3. Did you feel any of the following emotions today? </div>
                          <div className="action">
                            <Button variant="outline-primary"><i className="icon-edit"></i></Button>
                            <Button variant="outline-primary"><i className="icon-delete"></i></Button>
                          </div>
                        </div>

                        <div className="slAnswerWrap">
                          <label>Select all that apply</label>
                          <div className="inline-fields-wrap">
                                <div className="custom-check-field">
                                  <input type="checkbox" name="rate" value="Happy" />
                                  <label>Happy</label>
                                </div>

                                <div className="custom-check-field">
                                  <input type="checkbox" name="rate" value="Sad" />
                                  <label>Sad</label>
                                </div>

                                <div className="custom-check-field">
                                  <input type="checkbox" name="rate" value="Anxious" />
                                  <label>Anxious</label>
                                </div>

                                <div className="custom-check-field">
                                  <input type="checkbox" name="rate" value="Calm" />
                                  <label>Calm</label>
                                </div>


                          </div>
                        </div>
                      </div>
                    </div>
                      
                      

                    </Tab>
                    <Tab eventKey="result" title="View Results">
                    
                    <Row>
                        <Col lg={3}>
                          <div className="rBlock">
                            <div className="label">Assigned</div>
                            <div className="value">200</div>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="rBlock">
                            <div className="label">Response</div>
                            <div className="value theme-color">180</div>
                          </div>
                        </Col>

                        <Col lg={3}>
                          <div className="rBlock">
                            <div className="label">Average time taken</div>
                            <div className="value ">4:00 mnts</div>
                          </div>
                        </Col>

                        <Col lg={3}>
                          <div className="rBlock">
                            <div className="label">Completion rate</div>
                            <div className="value color-success">88.7%</div>
                          </div>
                        </Col>

                    </Row>

                    <div className="filterBar mt-4">
                      <div className="title">Total Questions [4]</div>
                      <a className="link text-decoration-none theme-color fw-500" href="">View by users <i className="icon-arrow-right"></i></a>
                    </div>
                    
                    <div className="viewQAWrap">
                      <div className="addEditBlockWrap pe-4 ps-4 pt-3 pb-3">
                          <div className="addEditBlockFlex">
                            <div className="title fw-700">1. How would you rate your overall mood today?</div>
                          </div>

                          <div className="progressWrap">
                              <div className="progressLinline">
                                <span>Very Good</span>
                                <ProgressBar variant="success" now={55} />
                                <span>145</span>
                              </div>

                              <div className="progressLinline">
                                <span>Good</span>
                                <ProgressBar variant="info" now={30} />
                                <span>25</span>
                              </div>

                              <div className="progressLinline">
                                <span>Neutral</span>
                                <ProgressBar now={56} />
                                <span>56</span>
                              </div>

                              <div className="progressLinline">
                                <span>Bad</span>
                                <ProgressBar now={12} variant="danger" />
                                <span>12</span>
                              </div>


                          </div>
                        </div>

                        <div className="addEditBlockWrap pe-4 ps-4 pt-3 pb-3">
                          <div className="addEditBlockFlex">
                            <div className="title fw-700">2. How often did you feel stressed today?</div>
                          </div>

                          <div className="progressWrap">
                              <div className="progressLinline">
                                <span>Very Good</span>
                                <ProgressBar variant="success" now={55} />
                                <span>145</span>
                              </div>

                              <div className="progressLinline">
                                <span>Good</span>
                                <ProgressBar variant="info" now={30} />
                                <span>25</span>
                              </div>

                              <div className="progressLinline">
                                <span>Neutral</span>
                                <ProgressBar now={56} />
                                <span>56</span>
                              </div>

                              <div className="progressLinline">
                                <span>Bad</span>
                                <ProgressBar now={12} variant="danger" />
                                <span>12</span>
                              </div>

                          </div>
                        </div>


                        <div className="addEditBlockWrap pe-4 ps-4 pt-3 pb-3">
                          <div className="addEditBlockFlex">
                            <div className="title fw-700">3. Did you feel any of the following emotions today? </div>
                          </div>

                          <div className="progressWrap">
                              <div className="progressLinline">
                                <span>Very Good</span>
                                <ProgressBar variant="success" now={55} />
                                <span>145</span>
                              </div>

                              <div className="progressLinline">
                                <span>Good</span>
                                <ProgressBar variant="info" now={30} />
                                <span>25</span>
                              </div>

                              <div className="progressLinline">
                                <span>Neutral</span>
                                <ProgressBar now={56} />
                                <span>56</span>
                              </div>

                              <div className="progressLinline">
                                <span>Bad</span>
                                <ProgressBar now={12} variant="danger" />
                                <span>12</span>
                              </div>

                          </div>
                        </div>


                    </div>

                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </section>

          <Footer />
        </div>
      </div>

      <Modal className="mw-530" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Questionnaire</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="form-group">
            <label>Questionnaire </label>
            <input type="text" value="Did you feel supported by your colleagues and friends today?" placeholder="Enter Questionnaire" />
          </div>
          <div className="form-group">
            <label>Answer type</label>
            <select>
              <option>Radio button</option>
              <option>Check Boxes</option>
              <option>Input Field</option>
              <option>Tetxarea</option>
            </select>
          </div>

          <div className="answerTypeBg">
            <div className="answerTypeWrap">
              <div className="answerType">
                <div className="custom-check-field custom-radio">
                  <input type="radio" value="Yes, very much" name="Answertype" />
                  <label>Yes, very much</label>
                </div>
              </div>
              <div className="action"><Button variant="outline-secondary"><i className="icon-delete"></i></Button></div>
            </div>

            <div className="answerTypeWrap">
              <div className="answerType">
                <div className="custom-check-field custom-radio">
                  <input type="radio" value="Yes, somewhat" name="Answertype" />
                  <label>Yes, somewhat</label>
                </div>
              </div>
              <div className="action"><Button variant="outline-secondary"><i className="icon-delete"></i></Button></div>
            </div>


            <div className="answerTypeWrap">
              <div className="answerType">
                <div className="custom-check-field custom-radio">
                  <input type="radio" value="Neutral" name="Answertype" />
                  <label>Neutral</label>
                </div>
              </div>
              <div className="action"><Button variant="outline-secondary"><i className="icon-delete"></i></Button></div>
            </div>

            <div className="answerTypeWrap">
              <div className="answerType">
                <div className="custom-check-field">
                  <input type="checkbox" value="Neutral" name="Answertype" />
                  <label>Checked</label>
                </div>
              </div>
              <div className="action"><Button variant="outline-secondary"><i className="icon-delete"></i></Button></div>
            </div>

            <div className="text-center mt-3">
              <Button variant="link" className="color-primary p-0 text-decoration-none">Add New Radio button +</Button>
            </div>
          </div>


        </Modal.Body>
        <Modal.Footer className="bt-0 pt-3 mt-1 pb-4">
          <Button variant="primary" onClick={toggleShowA}>
          Add New Questionnaire
          </Button>
          <Button className="me-0" variant="outline-secondary" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>

      <ToastContainer className="position-fixed">
      <Toast className="success" show={showA} onClose={toggleShowA}>
          <Toast.Header>
            <i className="icon-success"></i>
            <strong className="me-auto">Success</strong>
            <small>11 mins ago</small>
          </Toast.Header>
          <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default CreateAssessments;
