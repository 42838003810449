import axiosInstance from "./axios.service";
import { getLocalStorage } from "../utils";
import { azureLogin, groupSync, createTraining, fetchDepartments, fetchTrainingListByDateRange } from "./config";

const initialData = getLocalStorage("initialData");
const token = initialData?.intialData?.token || "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoiVTJGc2RHVmtYMTlJR0ZTTlZtMmkzN2VwdnBETG5VYmJNQjByQkgwYUlyY2gvZHdTWGp4cjA0QXg0M2pkRFMvVmZZSktkMk9jMzV6aWdPSnNWd1h1QWVnM0NyN3dBYS93L21NaStOR2NIclpTZTFQZ2QyZ3FnUjN3L0JxWnhYM2RwWHIraytMUGFKODg2RXRaOTRHVE9hd2JjV0N1TUtsTzhwYk0rQ3RKM05DYWpuSzdXdmgrNEN4STJpa0NjZzF2b0JaR2VJZUp3Z3hyTldYZ1R0a0VxVVM1cWlnMXhqVER6NW5kQmJiN0FFOD0iLCJpYXQiOjE3MjUzMzE1MjMsImV4cCI6MTcyNTQxNzkyM30.DUEyN4h8CGci6Uag8G76SAG7fHXB04ikVF9-4OZkBwA";



const apiRequest = async (
    url: string,
    method: "get" | "post" | "put",
    data?: any,
    requestToken?: string
) => {
    try {
        const authToken = requestToken || token;

        if (!authToken) {
            console.error('No authentication token found.');
            return null;
        }

        console.log('Retrieved Token:', authToken);

        const headers = {
            Authorization: `Bearer ${authToken}`,
        };

        const res = await axiosInstance({
            url,
            method,
            data,
            headers,
        });

        if (res.status === 200 || res.status === 201) {
            return res;
        }

        console.error('Unexpected response status:', res.status);
        return null;
    } catch (e) {
        console.error('API Request Error:', e);
        return null;
    }
};


export const azureUserLogin = async (data: any) => {
    return await apiRequest(`${azureLogin}/scdf/user/login`, "post", data);
};


export const createTrainingApi = async (data: any) => {
    return await apiRequest(`${createTraining}/create-training`, "post", data);
};


export const fetchDepartment = async () => {
    return await apiRequest(`${fetchDepartments}/fetch-department`, "get");
};


export const fetchTrainingsListByDateRange = async (data: any) => {
    return await apiRequest(`${fetchTrainingListByDateRange}/list-by-date-range`, "post", data);
};


export const groupSyncApi = async (data: any) => {
    return await apiRequest(`${groupSync}`, "post", data);
};